<template>
  <!-- <div style="height:60px"></div> -->
  <div class="custom-quill-editor" id="abcs">
    <QuillEditor
      ref="quillEditorRef"
      :toolbar="`#CommonEditor-toolbar${id}-doct`"
      :content="viewState.editValue"
      contentType="html"
      placeholder="Write Note..."
      @textChange="textChangeFun"
    >
      <template #toolbar>
        <div
          :id="`CommonEditor-toolbar${id}-doct`"
          :wordCnt="viewState.inputWordCnt"
        >
          <div class="time-stamp">{{ timeStamp }}</div>
          <select class="ql-header">
            <option selected value="">Body</option>
            <option value="2">Title</option>
          </select>
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-code-block"></button>
          <!-- <font class="wordCount">{{inputWordCnt}}/1000</font> -->
        </div>
      </template>
      <!-- <template #content>
        <div id="my-content">
          <div class="editor" v-html="content" @blur="content=$event.target.innerText" contenteditable="true"></div>
        </div>
      </template> -->
    </QuillEditor>
    <div class="editBtnDIV">
      <div class="editCancel" @click="cancelEditNote" style="cursor: pointer;">
        <span class="editCancelText">Cancel</span>
      </div>
      <div class="editSave" @click="saveEditNote" style="cursor: pointer;">
        <span class="editSaveText">Save Note</span>
      </div>
    </div>
  </div>
</template>
<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { defineComponent, reactive, ref, watch } from "vue";
export default defineComponent({
  props: {
    timeStamp: {
      default: "",
    },
    editValue: {
      default: "",
    },
    memoKey: {
      default: "",
    },
  },
  setup(props, context) {
    const viewState = reactive({
      key: props.memoKey,
      editValue: props.editValue,
      inputWordCnt: props.editValue.length,
    });

    const quillEditorRef = ref();

    const textChangeFun = () => {
      viewState.inputWordCnt =
        quillEditorRef.value.getQuill().getText().length - 1;
    };
    const getText = () => {
      return quillEditorRef.value.getQuill().getText();
    };
    const saveEditNote = () => {
      context.emit(
        "saveEditNote",
        viewState.key,
        quillEditorRef.value.getQuill().root.innerHTML
      );
    };
    const cancelEditNote = () => {
      context.emit("cancelEditNote");
    };

    watch(
      () => viewState.QuillEditorinputWordCnt,
      (newVal) => {
        if (newVal > 1000) {
          quillEditorRef.value
            .getQuill()
            .deleteText(
              1000,
              newVal - 1000,
              quillEditorRef.value.getQuill().getText()
            );
        }
      }
    );

    return {
      viewState,
      quillEditorRef,
      textChangeFun,
      getText,
      saveEditNote,
      cancelEditNote,
    };
  },
  components: {
    QuillEditor,
  },
});
</script>

<style lang="scss">
.custom-quill-editor {
  .editBtnDIV {
    height: 140px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .editCancel {
      width: 200px;
      height: 60px;
      margin-top: 30px;
      border: 1px solid $black;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      .editCancelText {
        font-weight: 700;
        font-size: 18px;
        line-height: 70px;
        /* or 389% */
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $dark-grey;
      }
    }
    .editSave {
      width: 200px;
      height: 60px;
      background: $black;
      margin-top: 30px;
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      .editSaveText {
        font-weight: 700;
        font-size: 18px;
        line-height: 70px;
        /* or 389% */
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $white;
      }
    }
  }

  // background: white;
  .ql-toolbar.ql-snow {
    background: white;
    padding: 20px 30px;
    padding-right: 100px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid $black;
    border-left: 1px solid $black;
    border-right: 1px solid $black;
    position: relative;
    &::after {
      font-weight: 500;
      font-size: 14px;
      line-height: 70px;
      text-align: right;
      letter-spacing: 0.08em;
      color: $dark-grey;
      opacity: 0.6;
      content: attr(wordCnt) "/1000";
      position: absolute;
      width: auto;
      height: 30px;
      right: 30px;
    }
    .time-stamp {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $secondary;
      padding: 9px 20px;
      font-weight: 700;
      font-size: 18px;
      letter-spacing: 0.08em;
      color: $white;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 0.5px;
        height: 30px;
        border-right: 0.5px solid rgba(6, 52, 62, 0.5);
        right: -30px;
      }
    }
    .ql-picker {
      display: inline-block !important;
      margin-left: 59px;
      margin-right: 0px;
    }
    button {
      margin-left: 50px;
      margin-right: 0px;
    }
  }

  .ql-container.ql-snow {
    background: white;
    border-bottom: 1px solid $black;
    border-left: 1px solid $black;
    border-right: 1px solid $black;
    height: 220px;
  }
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    margin-top: -9px;
    right: -20px;
    top: 50%;
    width: 18px;
  }
  .ql-editor {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    /* or 187% */
    letter-spacing: 0.1em;
    color: $dark-grey;
    padding-left: 30px;
    padding-right: 40px;
    padding-top: 20px;
  }
  .ql-editor.ql-blank::before {
    content: attr(data-placeholder);
    left: 30px;
    pointer-events: none;
    position: absolute;
    top: 0px;
    font-weight: 700;
    font-size: 18px;
    line-height: 70px;
    /* or 389% */
    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    color: $dark-grey;
    opacity: 0.2;
  }
  .ql-snow .ql-picker-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding-left: 0px;
    padding-right: 2px;
    position: relative;
    width: 100%;
  }
}
</style>
