<template>
  <div class="lessonDialogContainerCls">
    <el-dialog
      v-model="viewState.visible"
      :lock-scroll="true"
      width="100%"
      :show-close="false"
      fullscreen
      :destroy-on-close="true"
      custom-class="lessons-dialog"
    >
      <div class="lessonDIV" id="lessonDivSp">
        <div class="lessonHeader">
          <div class="titleDIV" id="titleDIV">
            <div class="title">Lessons</div>
            <div class="subTitle">レッスン</div>
          </div>
          <div
            class="closeLessonDIV"
            @click="viewState.visible = false"
            style="cursor: pointer"
          >
            <icon name="batu-icon" />
          </div>
        </div>
        <el-scrollbar always height="434px">
          <div class="lessonContainer">
            <template v-for="(item, index) of lessons" :key="index">
              <div class="lessonList" :class="{ over: item.over === true }">
                <div class="no-title-time" @click="selectLesson(index)">
                  <div class="no" :class="{ select: selectIndex == index }">
                    {{ item.no }}
                    <div
                      class="playProgress"
                      :style="{
                        width: `${lessons[selectIndex].progress_rate}%`,
                      }"
                      v-if="selectIndex == index"
                    />
                  </div>
                  <div class="lessonTitle-time">
                    <span
                      :class="{
                        marquee:
                          selectIndex == index &&
                          countTitleByHalfWidthConversion(item.title) > 18,
                      }"
                      class="lessonTitle"
                      >{{ item.title }}</span
                    >

                    <div class="lessonTime">
                      <icon
                        name="play-over-icon"
                        class="playOverIcon"
                        v-if="item.over"
                      />
                      <icon
                        name="playing-icon"
                        class="playIcon"
                        v-else-if="item.is_attendanced"
                      />
                      <icon name="not-playing-icon" class="playIcon" v-else />
                      <span class="lessonLength">{{
                        item.attendance_time
                      }}</span>
                    </div>
                  </div>
                </div>

                <!-- <el-dropdown @command="handleCommand" trigger="click">
                  <span class="el-dropdown-link">
                    <icon
                      name="play-option-icon"
                      class="playOption"
                      v-if="item.showBtn"
                      style="cursor: pointer"
                    />
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item command="テストを受ける"
                        >テストを受ける</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </div>
      <mydialog
        :isShowAlert="viewState.showAlert"
        title="Title"
        subTitle="タイトル"
        :message="viewState.alertMessage"
        cancel="Cancel"
        submit="OK"
        @cancelClick="viewState.showAlert = false"
        @submitClick="viewState.showAlert = false"
        @closeDialog="viewState.showAlert = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  ElDialog,
  // ElDropdown,
  // ElDropdownItem,
  // ElDropdownMenu,
  ElScrollbar,
} from "element-plus";
import mydialog from "@/components/CommonDialog/CommonDialog.vue";
import { defineComponent, nextTick, reactive } from "vue";
export default defineComponent({
  props: {
    lessons: {
      default: [],
    },
    selectIndex: {
      default: 0,
    },
  },
  setup(props, context) {
    const viewState = reactive({
      visible: false,
      alertMessage: "",
      showAlert: false,
    });

    const init = () => {
      viewState.visible = true;
      nextTick(() => {});
    };

    const handleCommand = (command) => {
      viewState.alertMessage = command;
      viewState.showAlert = true;
    };

    const selectLesson = (index) => {
      console.log("selectIndex", index);
      context.emit("selectLesson", index);
      viewState.visible = false;
    };
    
    // タイトルを半角換算で文字数カウント 
    const countTitleByHalfWidthConversion = (title) => {
      let count = 0;
      for (let i = 0; i < title.length; i++) {
        if(title[i].match(/[ -~]/) ) {
          count += 1;
        } else {
          count += 2;
        }
      }
      console.log(count)
      return count;
    }

    return {
      viewState,
      init,
      handleCommand,
      selectLesson,
      countTitleByHalfWidthConversion,
    };
  },
  components: {
    ElDialog,
    // ElDropdown,
    // ElDropdownItem,
    // ElDropdownMenu,
    ElScrollbar,
    mydialog,
  },
});
</script>

<style lang="scss">
.lessonDialogContainerCls {
  .el-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      rgba(54, 131, 245, 0.1);
    backdrop-filter: blur(5px);
  }

  .lessons-dialog {
    .el-dialog__body {
      // background: $white;
      // height: 494px;
      // width: 315px;
      padding: 0;
      overflow: scroll;
      margin: 0 auto;
      .lessonDIV {
        margin: auto;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // opacity: 0.9;
        max-width: 315px;
        width: 315px;
        height: 494px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
        .lessonHeader {
          background: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 60px;
          border-bottom: 1px solid $black;
          .titleDIV {
            height: 100%;
            align-items: center;
            margin-left: 20px;
            display: flex;
            flex-direction: row;
            .title {
              width: fit-content;
              height: 30px;
              text-align: left;
              font-weight: 700;
              font-size: 30px;
              line-height: 100%;
              letter-spacing: 0.06em;
              color: $black;
            }
            .subTitle {
              font-weight: 300;
              font-size: 14px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.5;
              align-self: flex-start;
              margin-top: 24px;
              margin-left: 10px;
              // opacity: 0.5;
            }
          }
          .closeLessonDIV {
            cursor: pointer;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $primary;
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
        .el-scrollbar {
          background: transparent;
        }
        .el-scrollbar__bar.is-horizontal {
          display: none;
        }
        .el-scrollbar__bar.is-vertical {
          width: 5px;
          top: 5px;
          right: 5px;
        }
        .el-scrollbar__bar {
          border-radius: 2px;
          bottom: 5px;
          overflow: hidden;
        }
        .el-scrollbar__thumb {
          background-clip: content-box;
          background-color: $primary;
          box-shadow: 0px 20px 30px rgba(72, 159, 157, 0.25);
          opacity: 1;
        }
        .lessonContainer {
          background: rgba(255, 255, 255, 0.9);
          backdrop-filter: blur(100px);
          // max-height: 434px;
          // overflow-y: hidden;

          // &::-webkit-scrollbar {
          //   width: none;
          //   // background: rgba(255, 255, 255, 0);
          // }

          // &::-webkit-scrollbar-thumb {
          //   height: 50px;
          //   background-clip: content-box;
          //   border-radius: 2px;
          //   background-color: $primary;
          //   border-right: 5px solid transparent;
          //   border-top: 5px solid transparent;
          //   border-bottom: 5px solid transparent;
          //   box-shadow: 0px 20px 30px rgba(72, 159, 157, 0.25);
          // }
          .over {
            background-color: #f0f3f8;
          }
          .lessonList {
            background: rgba(255, 255, 255, 0.9);
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            padding-right: 28px;
            position: relative;
            &::after {
              content: "";
              width: 255px;
              height: 0px;
              border-bottom: 1px solid $white;
              position: absolute;
              bottom: 1px;
              left: 0;
            }
            .no-title-time {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              height: 60px;
              .no {
                width: 40px;
                height: 40px;
                margin-left: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-top: 15px;
                font-family: "Roboto Condensed", sans-serif;
                font-weight: 700;
                font-size: 18px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: $black;
              }
              .select {
                // margin-top: 25.16px;
                background: $white;
                box-shadow: 0px 20px 30px rgba(72, 159, 157, 0.25);
                position: relative;
                .playProgress {
                  position: absolute;
                  height: 0px;
                  border-bottom: 4px solid #e24242;
                  left: 0px;
                  bottom: 0px;
                }
              }
              .lessonTitle-time {
                display: flex;
                flex-direction: column;
                padding-top: 15px;
                padding-bottom: 12px;
                margin-left: 20px;
                overflow: hidden;
                max-width: 176px;
                height: 60px;
                .lessonTitle {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 100%;
                  white-space: nowrap;
                  letter-spacing: 0.1em;
                  color: $black;
                  max-width: 198px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .marquee {
                  width: fit-content;
                  max-width: fit-content;
                  animation: flowing-sp 10s linear infinite;
                }
                @keyframes flowing-sp {
                  100% {
                    transform: translateX(-100%);
                  }
                }
                .lessonTime {
                  margin-top: 7px;
                  display: flex;
                  align-items: center;
                  .playIcon {
                    width: 8px;
                    height: 10px;
                    margin-right: 9.55px;
                  }
                  .playOverIcon {
                    width: 12px;
                    height: 12px;
                    margin-right: 5.55px;
                  }
                  .lessonLength {
                    height: 10px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 100%;
                    letter-spacing: 0.09em;
                    color: rgba(89, 88, 87, 0.8);
                  }
                }
              }
            }
            .playOption {
              width: 4px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
