<template>
  <div>
    <div
      class="videoDiv"
      :class="{
        lessonDivClose: !viewState.lessonDivOpen,
        lessonDivOpen: viewState.lessonDivOpen && !viewState.isFirstOpen,
      }"
    >
      <div class="videoHeader">
        <div class="no-title-time">
          <div class="no">
            {{ viewState.lessons[viewState.selectIndex].no }}
            <div
              class="playProgress"
              :style="{
                width: viewState.lessons[viewState.selectIndex].progress_rate,
              }"
            />
          </div>
          <div class="lessonTitle-time-sp">
            <span
              :class="{
                marquee:
                  viewState.lessons[viewState.selectIndex].title.length > 18,
              }"
              class="lessonTitle"
              >{{ viewState.lessons[viewState.selectIndex].title }}</span
            >
            <div class="lessonTime">
              <icon
                name="play-over-icon"
                class="playOverIcon"
                v-if="viewState.lessons[viewState.selectIndex].over"
              />
              <icon name="playing-icon" class="playIcon" v-else />
              <span class="lessonLength">{{
                viewState.lessons[viewState.selectIndex].attendance_time
              }}</span>
            </div>
          </div>
        </div>
        <div class="menuDIV">
          <div
            class="listMenu"
            @click="showLessonsListMenuFun"
            style="cursor: pointer"
          >
            <icon name="video-list-menu-icon" />
          </div>
        </div>
      </div>
      <div class="videoPlayer">
        <video-js
          v-if="viewState.contentsType === 'video'"
          id="video"
          class="video-js vjs-default-skin"
          controls
          preload="auto"
          :poster="viewState.material.movie.image_url"
          playsinline
        >
          <source
            :src="
              `${env.VUE_APP_API_BASE_URL}/api/materials/${viewState.materialCode}/manifest?key_type=code&access_token=${token}`
            "
            type="application/x-mpegURL"
          />
        </video-js>
        <div v-if="viewState.contentsType === 'slide'">
          <carousel :items-to-show="1" class="slide">
            <slide v-for="index in viewState.pdfPageCount" :key="index">
              <canvas class="pdf-view" :ref="canvasRefs[index - 1]"></canvas>
            </slide>

            <template #addons="{ currentSlide, slidesCount }">
              <navigation>
                <template #prev>
                  <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                    <title>arrowLeft</title>
                    <path
                      d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
                    ></path>
                  </svg>
                </template>
                <template #next>
                  <span @click="slideAction(currentSlide, slidesCount)">
                    <svg class="carousel__icon" viewBox="0 0 24 24" role="img">
                      <title>arrowRight</title>
                      <path
                        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
                      ></path>
                    </svg>
                  </span>
                </template>
              </navigation>
              <!-- <pagination /> -->
            </template>
          </carousel>
        </div>
        <div v-if="viewState.isDisplayed && viewState.contentsType === 'test'" class="test-div">
          <div class="test">
            <template v-if="viewState.testResult.is_correct !== null">
              <h2 class="title">
                確認テスト終了
              </h2>

              <div class="message-section">
                <p>
                  確認テストは再受講可能です。<br />
                  このまま再受講されたい場合は、ページの再読み込みを実施してください。
                </p>
              </div>
            </template>
            <template v-else>
              <h2 class="title">
                確認テスト
              </h2>

              <button
                class="submit-area-button"
                style="margin-top:50px"
                @click="showTestDialog"
              >
                テストを開始
                <icon
                  name="swiper-button-next-btn"
                  class="swiper-button-next-btn-icon"
                ></icon>
              </button>
            </template>
          </div>
        </div>
      </div>
      <div class="lessonDIV">
        <div class="lessonHeader">
          <div
            class="titleDIV"
            :style="{ display: !viewState.slide2rightFlg ? 'none' : 'flex' }"
          >
            <div class="title">Lessons</div>
            <div class="subTitle">レッスン</div>
          </div>
          <icon
            class="slideDIV"
            name="slide2right-icon"
            @click="lessonDivCloseFun"
            v-if="viewState.slide2rightFlg"
            style="cursor: pointer"
          />
          <icon
            style="margin-left: 40px; cursor: pointer"
            class="slideDIV"
            name="slide2left-icon"
            @click="lessonDivOpenFun"
            v-if="!viewState.slide2rightFlg"
          />
        </div>
        <el-scrollbar always>
          <div class="lessonContainer">
            <template v-for="(item, index) of viewState.lessons" :key="index">
              <div class="lessonList" :class="{ over: item.over === true }">
                <div class="no-title-time" @click="selectLesson(index)">
                  <div
                    class="no"
                    :class="{ select: viewState.selectIndex == index }"
                    style="cursor: pointer"
                  >
                    {{ item.no }}
                    <div
                      class="playProgress"
                      :style="{ width: `${item.progress_rate}%` }"
                      v-if="viewState.selectIndex == index"
                    />
                  </div>
                  <div
                    class="lessonTitle-time"
                    :style="{
                      display: !viewState.slide2rightFlg ? 'none' : 'flex',
                    }"
                  >
                    <span
                      :class="{
                        marquee:
                          viewState.selectIndex == index &&
                          countTitleByHalfWidthConversion(item.title) > 17,
                      }"
                      style="cursor: pointer"
                      class="lessonTitle"
                      >{{ item.title }}</span
                    >

                    <div class="lessonTime">
                      <icon
                        name="play-over-icon"
                        class="playOverIcon"
                        v-if="item.over"
                      />
                      <icon
                        name="playing-icon"
                        class="playIcon"
                        v-else-if="item.is_attendanced"
                      />
                      <icon name="not-playing-icon" class="playIcon" v-else />
                      <span class="lessonLength">{{
                        item.attendance_time
                      }}</span>
                    </div>
                  </div>
                </div>

                <!-- <el-dropdown
                @command="handleCommand"
                placement="bottom-end"
                trigger="click"
              >
                <div class="el-dropdown-link dropdown-icon">
                  <icon
                    :style="{
                      display: !viewState.slide2rightFlg ? 'none' : 'block',
                    }"
                    name="play-option-icon"
                    class="playOption"
                    v-if="item.showBtn"
                    style="cursor: pointer"
                  />
                </div>

                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      class="dropdownCls"
                      command="テストを受ける"
                      >テストを受ける</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </div>
      <div
        class="editDIV"
        v-show="viewState.showEditFlg"
        :class="{
          showEdit: viewState.showEdit,
          hideEdit: !viewState.showEdit && viewState.hideEdit,
        }"
      >
        <div class="editHeader">
          <span class="time" :wordCnt="viewState.inputWordCnt">{{
            display.toMemoTime(videoStore.currentTime)
          }}</span>
          <div class="closeBtn" @click="hideEditFun" style="cursor: pointer">
            <icon name="batu-icon" class="closeIcon" />
          </div>
        </div>
        <div class="editorDIV">
          <QuillEditor
            ref="quillEditorRef"
            toolbar="#CommonVideo-toolbar"
            placeholder="Write Note..."
            @textChange="textChangeFun"
          >
            <template #toolbar>
              <div id="CommonVideo-toolbar">
                <select class="ql-header">
                  <option selected value="">Body</option>
                  <option value="2">Title</option>
                </select>
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-code-block"></button>
              </div>
            </template>
          </QuillEditor>
        </div>
        <div class="btnDIV">
          <div class="cancelBtn" @click="hideEditFun" style="cursor: pointer">
            <span class="cancelText">Cancel</span>
          </div>
          <div class="submitBtn" @click="saveNote" style="cursor: pointer">
            <span class="submitText">Save Note</span>
          </div>
        </div>
        <div :class="{ saved: viewState.saved }" v-if="viewState.saved">
          <icon name="saved-icon" class="saveIcon" />
          <span class="savedText">SAVED</span>
        </div>
      </div>

      <!-- 動画コントローラのSP用操作ダイアログ -->
      <template v-if="viewState.isDisplayed">
        <editorDialog
          v-show="viewState.editorDialogVisible"
          ref="editorDialogRef"
          @editorText="saveEditorText"
        ></editorDialog>
      </template>

      <template v-if="viewState.isDisplayed">
        <lessonsDialog
          v-if="viewState.showLessonsListMenu"
          :selectIndex="viewState.selectIndex"
          :lessons="viewState.lessons"
          ref="lessonsDialogRef"
          @selectLesson="selectLesson"
        >
        </lessonsDialog>
      </template>
    </div>

    <mydialog
      class="finish-dialog"
      :isShowAlert="viewState.isCompletedDialog"
      :html="viewState.isCompletedDialogMsg"
      :submit="viewState.isCompletedDialogBtn"
      @submitClick="modalButtonFunction"
    />

    <testDialog
      v-if="viewState.testDialogVisible"
      ref="testDialogRef"
      :material="viewState.material"
      :testValueObject="viewState.testValueObject"
      :testResult="viewState.testResult"
      @resetTestView="resetTestView"
      @finishCapturePaper="finishCapturePaper"
      @finishModalContent="finishModalContent"
      @toggleTestValue="toggleTestValue"
      @radioTestValue="radioTestValue"
      @sendTestValue="sendTestValue"
      @capturePaper="capturePaper"
    />
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { /*ElDropdown,*/ ElScrollbar } from "element-plus";
// import { ElDropdownItem } from "element-plus";
// import { ElDropdownMenu } from "element-plus";
import mydialog from "@/components/CommonDialog/CommonDialog.vue";
import lessonsDialog from "@/views/view/lessonsDialog.vue";
import editorDialog from "@/views/view/editorDialog.vue";
import testDialog from "@/views/view/testDialog.vue";
import display from "@/utils/display";
import videojs from "video.js";
import "@videojs/http-streaming";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation /*,Pagination*/ } from "vue3-carousel";
import http from "@/utils/http";
const pdfjsLib = require("pdfjs-dist");
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useGlobalStore } from "@/store/global";
export default defineComponent({
  props: {
    lectureCode: {
      default: "",
    },
    chapterCode: {
      default: "",
    },
    materialCode: {
      default: "",
    },
    lecture: {
      default: {},
    },
    material: {
      default: {},
    },
    lessons: {
      default: [],
    },
    chapters: {
      default: [],
    },
    selectIndex: {
      default: 0,
    },
  },
  setup(props, context) {
    const env = process.env;
    const store = useStore();
    const route = useRoute();
    const token = store.getters.token;
    const editorUsingState = store.state.editorUsing;
    const isSp = store.state.isSp;
    const userStore = store.state.user;
    const { videoStore } = useGlobalStore();

    const contensType = {
      1: "video",
      2: "slide",
      3: "test",
    };


    const viewState = reactive({
      isDisplayed: false,
      lectureCode: props.lectureCode,
      chapterCode: props.chapterCode,
      materialCode: props.materialCode,
      lecture: props.lecture,
      material: props.material,
      response: {
        status: 0,
        data: {},
      },
      errors: [],
      isFirstOpen: true,
      lessonDivOpen: true,
      editorDialogVisible: false,
      alertMessage: "",
      showAlert: false,
      showLessonsListMenu: false,
      saved: false,
      inputWordCnt: 0,
      showEdit: false,
      showEditFlg: false,
      hideEdit: false,
      slide2rightFlg: true,
      selectIndex: props.selectIndex,
      progress: "25%",
      lessons: props.lessons,
      player: null,
      pdf: null,
      pdfPageCount: 0,
      contentsType: contensType[props.material.content_type_id],
      recordKey: "",
      recordCreated: false,
      currentSlideIndex: 0,
      testValueObject: {},
      timer: null,
      isCompletedDialog: false,
      isCompletedDialogMsg: "",
      isCompletedDialogBtn: "",
      nextMaterial: {},
      isFirstSetStartTime: true,
      testDialogVisible: false,
      testResult: {
        is_correct: null,
        result_detail: {}
      },
    });

    const quillEditorRef = ref();
    const editorDialogRef = ref();
    const lessonsDialogRef = ref();
    const testDialogRef = ref();
    const canvasRefs = [];

    // 現チャプターのインデックス番号
    const chapterIndex = props.chapters.findIndex((el) => {
      return el.code === props.chapterCode
    })

    const lessonDivCloseFun = () => {
      viewState.lessonDivOpen = false;
      viewState.isFirstOpen = false;
      setTimeout(() => {
        viewState.slide2rightFlg = false;
      }, 200);
    };
    const lessonDivOpenFun = () => {
      viewState.lessonDivOpen = true;
      setTimeout(() => {
        viewState.slide2rightFlg = true;
      }, 300);
    };
    const selectLesson = (selectIndex) => {
      window.location = `/view/${viewState.lectureCode}/${viewState.chapterCode}/${viewState.lessons[selectIndex].code}`;
    };
    const saveEditorText = async (memoKey, text) => {
      // 新規
      await http
        .post(
          `/api/materials/${viewState.materialCode}/memos?key_type=code`,
          {
            memo: text,
            marked_time: videoStore.currentTime,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });

      context.emit("fetchMemos");
    };
    const showEditFun = () => {
      viewState.player.pause(); // 停止
      if (!editorUsingState) {
        store.dispatch("calcIsEditorUsing", true);
        viewState.showEdit = true;
        viewState.showEditFlg = true;
        viewState.saved = false;
      } else {
        return;
      }
    };
    const showSpEditFun = () => {
      viewState.player.pause(); // 停止
      viewState.saved = false;
      viewState.editorDialogVisible = true;
      nextTick(() => {
        editorDialogRef.value.init("", "");
      });
    };
    const hideEditFun = () => {
      viewState.showEdit = false;
      setTimeout(() => {
        viewState.hideEdit = true;
        quillEditorRef.value.getQuill().deleteText(0, viewState.inputWordCnt);
        store.dispatch("calcIsEditorUsing", false);
        viewState.showEditFlg = false;
      }, 500);
    };
    const textChangeFun = () => {
      viewState.inputWordCnt =
        quillEditorRef.value.getQuill().getText().length - 1;
    };
    const saveNote = async () => {
      await http
        .post(
          `/api/materials/${viewState.materialCode}/memos?key_type=code`,
          {
            memo: quillEditorRef.value.getQuill().root.innerHTML,
            marked_time: videoStore.currentTime,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });

      context.emit("fetchMemos");

      viewState.saved = true;
      setTimeout(hideEditFun, 1000);
    };
    const handleCommand = (command) => {
      viewState.alertMessage = command;
      viewState.showAlert = true;
    };
    const showLessonsListMenuFun = () => {
      viewState.showLessonsListMenu = true;

      nextTick(() => {
        lessonsDialogRef.value.init();
      });
    };
    const nextLesson = () => {
      // 次のマテリアル
      if (viewState.selectIndex < viewState.lessons.length - 1) {
        selectLesson(++viewState.selectIndex);
      }
    };
    // const preLesson = () => {
    //   // 前のマテリアル
    //   if (viewState.selectIndex > 0) {
    //     selectLesson(--viewState.selectIndex);
    //   }
    // };
    const createViewRecord = () => {
      if (viewState.recordCreated) {
        return;
      }

      http
        .post(
          `/api/view`,
          {
            material_id: viewState.material.id,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          viewState.recordKey = response.data.key;
          viewState.recordCreated = true;
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });
    };
    const sendViewRecord = (seconds, isCompleted) => {
      if (!viewState.recordCreated) {
        return;
      }

      const params = {
        view_seconds: seconds,
      };

      if (isCompleted) {
        params.is_finished = 1;
      }

      return http
        .put(`/api/view/${viewState.recordKey}`, params, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });
    };
    const captureVideo = async () => {
      // videoの進行にあわせて処理を行う
      const seconds = viewState.player.currentTime();
      const intSeconds = parseInt(seconds, 10);
      videoStore.setCurrentTime(display.toHis(intSeconds));

      if (intSeconds > 0 && !viewState.recordCreated) {
        createViewRecord();
      } else if (intSeconds % 5 === 0) {
        // 5秒毎に視聴記録をとる
        sendViewRecord(intSeconds);
      }

      if (seconds > 0) {
        const duration = viewState.player.duration();

        // 動画の読み込み直後は動画時間が正常に取れないことがあるため、
        // 正常に取れ始めてから完了判定を行う
        if (duration > 0 && seconds >= duration) {
          // 視聴記録完了
          // バックエンド側に合わせて、視聴完了時の再生時間はミリ秒以下を四捨五入した秒数を送る
          const roundedSeconds = Math.round(seconds);
          const isCompleted = true;
          await sendViewRecord(roundedSeconds, isCompleted);

          // 停止
          clearTimeout(viewState.timer);
          viewState.timer = null;

          // 最後なので3秒後に次のマテリアル
          if (!viewState.timer) {
            viewState.timer = setTimeout(() => {
              finishModalContent() // 受講終了時のモーダルコンテンツ定義
            }, 3000)
          }
        }
      }
    };

    const fetchMaterial = () => {
      http
        .get(`/api/materials?chapter_id=${props.chapters[chapterIndex + 1].id}`)
        .then((response) => {
          viewState.nextMaterial = response.data;
        })
        .catch((error) => {
          console.log(error)
        });
    };
    const finishModalContent = () => {
      // チャプター終了の条件を調べる
      if(viewState.lessons.length -1 === viewState.selectIndex && props.chapters.length -1 === chapterIndex) {
        console.log('講座内の最終チャプター、かつ最終レッスンのスライド')
        viewState.isCompletedDialogMsg = 
          `
          <p class="finish-message">講座内の最後のレッスンが終わりました。 <br> お疲れ様でした。</p>
          `
        viewState.isCompletedDialogBtn = "閉じる"
      } else if(viewState.lessons.length -1 === viewState.selectIndex) {
        fetchMaterial()
        console.log('チャプター内の最終レッスン')
        viewState.isCompletedDialogMsg = 
          `
          <p class="finish-message">レッスンの受講が終わりました。 <br> 次のチャプターへ進んでください。</p>
          <p class="next-message">NEXT CHAPTER <br> ${props.chapters[chapterIndex + 1].name}</p>
          `
        viewState.isCompletedDialogBtn = "次へ進む"
      } else {
        console.log('チャプター内の次のレッスンがある場合')
        viewState.isCompletedDialogMsg = 
          `
          <p class="finish-message">レッスンの受講が終わりました。 <br> 次のレッスンに進んでください。</p>
          <p class="next-message">NEXT LESSON <br> ${viewState.lessons[viewState.selectIndex + 1].name}</p>
          `
        viewState.isCompletedDialogBtn = "次へ進む"
      }
      viewState.isCompletedDialog = true;
    }
    const modalButtonFunction  = () => {
      if(viewState.lessons.length -1 === viewState.selectIndex && props.chapters.length -1 === chapterIndex) {
        viewState.isCompletedDialog = false
      } else if(viewState.lessons.length -1 === viewState.selectIndex) {
        window.location = `/view/${viewState.lectureCode}/${props.chapters[chapterIndex + 1].code}/${viewState.nextMaterial[0].code}`;
      } else {
        nextLesson()
      }
    }
    const capturePaper = () => {
      createViewRecord();
      // 5秒ごとに実行
      if (!viewState.timer) {
        viewState.timer = setInterval(sendViewRecord.bind(null, 0), 5000); // 常に0秒で記録
      }
    };
    const finishCapturePaper = () => {
      const isCompleted = true;
      sendViewRecord(0, isCompleted);
      // 停止
      clearTimeout(viewState.timer);
      viewState.timer = null;
      console.log('スライド終了')
    };
    const slideAction = async (currentSlide, slidesCount) => {
      // 初回スライドで視聴開始
      if (currentSlide === 0) {
        capturePaper();
      }

      // 最終スライドで視聴完了
      if (currentSlide === slidesCount - 1) {
        finishCapturePaper();
        if(!props.material.hasOwnProperty('test')) {
          finishModalContent() // 受講終了時のモーダルコンテンツ定義
        }
      }
    };

    const initTest = () => {
      initTestValue();

      // テストスライド情報に共通スライドを設定する（概要、確認）
      viewState.material.test.question_count =
        viewState.material.test.test_questions.length;
      viewState.material.test.test_questions.unshift({
        id: "description",
        question: "概要",
      });
      viewState.material.test.test_questions.push({
        id: "confirm",
        question: "確認",
      });
      viewState.material.test.test_questions.push({
        id: "result",
        question: "結果",
      });
    };
    const initTestValue = () => {
      // 入力用データ作成
      viewState.testValueObject = {};
      viewState.material.test.test_questions.forEach((questionItem) => {
        if (!questionItem?.key) {
          return;
        }
        if (!(questionItem.key in viewState.testValueObject)) {
          viewState.testValueObject[questionItem.key] = {};
        }
        if (questionItem.test_choices) {
          questionItem.test_choices.forEach((choice) => {
            viewState.testValueObject[questionItem.key][choice.key] = false;
          });
        }
      });
    };
    const resetTestView = () => {
      viewState.contentsType = "";
      initTestValue();
      viewState.currentSlideIndex = 0;
      viewState.contentsType = "test";
    };
    const toggleTestValue = (questionKey, choiceKey) => {
      viewState.testValueObject[questionKey][choiceKey] = !viewState
        .testValueObject[questionKey][choiceKey];
    };
    const radioTestValue = (questionKey, choiceKey) => {
      for (const [answerKey] of Object.entries(
        viewState.testValueObject[questionKey]
      )) {
        viewState.testValueObject[questionKey][answerKey] = false;
      }
      viewState.testValueObject[questionKey][choiceKey] = true;
    };
    const sendTestValue = async (testKey) => {
      const params = {
        test_questions: [],
      };

      for (const [questionKey, answers] of Object.entries(
        viewState.testValueObject
      )) {
        const answerKeys = [];
        for (const [answerKey, answerValue] of Object.entries(answers)) {
          if (answerValue) {
            answerKeys.push(answerKey);
          }
        }
        params.test_questions.push({
          key: questionKey,
          answer_keys: answerKeys,
        });
      }

      await http
        .post(`/api/tests/${testKey}/result`, params, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.dir(response.data);
          viewState.testResult = response.data;

          testDialogRef.value.nextTestView();
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });

      // 視聴記録完了
      finishCapturePaper();
    };
    const finishTestView = () => {
      finishModalContent() // 受講終了時のモーダルコンテンツ定義
    }
    const showTestDialog = () => {
      viewState.testDialogVisible = true;
      nextTick(() => {
        testDialogRef.value.init();
      });
    };

    // タイトルを半角換算で文字数カウント 
    const countTitleByHalfWidthConversion = (title) => {
      let count = 0;
      for (let i = 0; i < title.length; i++) {
        if(title[i].match(/[ -~]/) ) {
          count += 1;
        } else {
          count += 2;
        }
      }
      return count;
    }

    watch(
      () => viewState.inputWordCnt,
      (newVal) => {
        if (newVal > 1000) {
          quillEditorRef.value
            .getQuill()
            .deleteText(
              1000,
              newVal - 1000,
              quillEditorRef.value.getQuill().getText()
            );
        }
      }
    );

    //-videojs---------------------------------------------
    // カスタムコンポーネント
    const Button = videojs.getComponent("Button");
    // 次のマテリアル
    class nextCustomButton extends Button {
      constructor(player, options = {}) {
        super(player, options);
      }
      handleClick(/*event*/) {
        nextLesson();
      }
      createEl() {
        return videojs.dom.createEl(
          "button",
          {
            className: "vjs-next-control vjs-control vjs-button",
            innerHTML:
              '<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 1px;" height="20px" viewBox="0 0 24 24" width="20px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z"/></svg>',
          },
          {
            type: "button",
            title: "次の動画",
          }
        );
      }
    }
    videojs.registerComponent("nextCustomButton", nextCustomButton);

    // メモ
    class noteButton extends Button {
      constructor(player, options = {}) {
        super(player, options);
      }
      handleClick(/*event*/) {
        // option access this.options
        isSp ? showSpEditFun() : showEditFun();
      }
      createEl() {
        return videojs.dom.createEl(
          "button",
          {
            className: "vjs-next-control vjs-control vjs-button",
            innerHTML:
              '<svg xmlns="http://www.w3.org/2000/svg" style="margin-top: 1px;" height="20px" viewBox="0 0 24 24" width="20px" fill="#FFFFFF"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><path d="M19,3h-4.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5 C21,3.9,20.1,3,19,3z M12,2.75c0.41,0,0.75,0.34,0.75,0.75S12.41,4.25,12,4.25s-0.75-0.34-0.75-0.75S11.59,2.75,12,2.75z M19,19H5 V5h14V19z"/><polygon points="15.08,11.03 12.96,8.91 7,14.86 7,17 9.1,17"/><path d="M16.85,9.27c0.2-0.2,0.2-0.51,0-0.71l-1.41-1.41c-0.2-0.2-0.51-0.2-0.71,0l-1.06,1.06l2.12,2.12L16.85,9.27z"/></g></g></svg>',
          },
          {
            type: "button",
            title: "メモ",
          }
        );
      }
    }
    videojs.registerComponent("noteButton", noteButton);

    // 再生ボタン
    const PlayToggle = videojs.getComponent("PlayToggle");
    class customPlayToggle extends PlayToggle {
      constructor(player, options = {}) {
        super(player, options);
      }
      handlePlay(event) {
        if (event.target.player.currentTime() === 0) {
          // Safariでリプレイ機能が動かない（少し先での再生は可能）
          event.target.player.currentTime(0.5);
        }
        super.handlePlay(event);
      }
    }
    videojs.registerComponent("customPlayToggle", customPlayToggle);
    //-videojs---------------------------------------------

    //-pdfjs---------------------------------------------
    const initPDF = async () => {
      const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");
      pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
      viewState.pdf = await pdfjsLib.getDocument(
        viewState.material.presentasion_slide.signed_url
      ).promise;
      viewState.pdfPageCount = viewState.pdf.numPages;
      for (let index = 0; index < viewState.pdfPageCount; index++) {
        canvasRefs[index] = ref();
      }
      nextTick(() => {
        renderPDF();
      });
    };

    const renderPDF = async () => {
      for (let index = 0; index < viewState.pdfPageCount; index++) {
        const page = await viewState.pdf.getPage(index + 1);
        // TOD: width,heightにおさまるようにscaleを適切に変更する必要がありそう
        const viewport = page.getViewport({ scale: isSp ? 0.3 : 0.8 });
        const context = canvasRefs[index].value.getContext("2d");
        canvasRefs[index].value.width = viewport.width;
        canvasRefs[index].value.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        page.render(renderContext).promise;
      }
    };

    //-pdfjs---------------------------------------------

    onMounted(async () => {
      if (viewState.contentsType === "slide") {
        initPDF();
      }

      if (viewState.contentsType === "test") {
        initTest();
      }

      if (viewState.contentsType === "video") {
        viewState.timer = setInterval(captureVideo, 1000);

        // videojs
        const overrideNative = true;

        // videojsは生成後、オプション変更が出来ないため、
        // 利用のたびに1度pプレイヤーを破棄する必要がある
        if (videojs.getPlayers()["video"]) {
          videoStore.clearPlayer();
          delete videojs.getPlayers()["video"];
        }

        viewState.player = videojs("video", {
          html5: {
            vhs: {
              overrideNative: overrideNative,
            },
            nativeVideoTracks: !overrideNative,
            nativeAudioTracks: !overrideNative,
            nativeTextTracks: !overrideNative,
          },
          bigPlayButton: true,
          playbackRates: [1, 1.25, 1.5, 2],
          ErrorDisplay: true,
          controlBar: {
            playToggle: false,
            fullscreenToggle: true,
            muteToggle: false,
            remainingTimeDisplay: !isSp,
            currentTimeDisplay: true,
            timeDivider: !isSp,
            durationDisplay: !isSp,
            playbackRateMenuButton: true,
          },
          fluid: true,
          language: "ja",
          languages: {
            ja: {
              Play: "再生",
              Pause: "一時停止",
              "Current Time": "現在の時間",
              Duration: "長さ",
              "Remaining Time": "残りの時間",
              "Stream Type": "ストリームの種類",
              LIVE: "ライブ",
              Loaded: "ロード済み",
              Progress: "進行状況",
              Fullscreen: "フルスクリーン",
              "Non-Fullscreen": "フルスクリーン以外",
              Mute: "ミュート",
              Unmute: "ミュート解除",
              "Playback Rate": "再生レート",
              Subtitles: "サブタイトル",
              "subtitles off": "サブタイトル オフ",
              Captions: "キャプション",
              "captions off": "キャプション オフ",
              Chapters: "チャプター",
              "Exit Picture-in-Picture": "ピクチャーインピクチャー 終了",
              "Picture-in-Picture": "ピクチャーインピクチャー",
              "You aborted the media playback": "動画再生を中止しました",
              "A network error caused the media download to fail part-way.":
                "ネットワークエラーにより動画のダウンロードが途中で失敗しました",
              "The media could not be loaded, either because the server or network failed or because the format is not supported.":
                "再生有効期限超過、サーバーエラー、ネットワークエラー等の原因により、動画を読み込めませんでした",
              "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.":
                "動画の破損、またはお使いのブラウザがサポートしていない機能が動画に使用されていたため、動画の再生が中止されました",
              "No compatible source was found for this media.":
                "この動画に対して互換性のあるソースが見つかりませんでした",
              Replay: "再生",
            },
          },
        });
        viewState.player.controlBar.addChild("customPlayToggle", {}, 0);
        viewState.player.controlBar.addChild("nextCustomButton", {}, 9);
        viewState.player.controlBar.addChild("noteButton", {}, 10);
        videoStore.setPlayer(viewState.player);

        // disabled right click
        const myVideo = document.getElementById("video");
        if (myVideo.addEventListener) {
          myVideo.addEventListener(
            "contextmenu",
            function(e) {
              e.preventDefault();
            },
            false
          );
        } else {
          myVideo.attachEvent("oncontextmenu", function() {
            window.event.returnValue = false;
          });
        }

        if (route.query.start_time) {
          // 再生開始時間の指定がされていた場合、指定時間から再生する
          myVideo.children[0].addEventListener(
            "loadeddata",
            function(e) {
              if (!viewState.isFirstSetStartTime) {
                // 複数回イベントを検知した場合、2回目以降はskipする
                return;
              }
              const startTime = route.query.start_time;
              viewState.player.currentTime(startTime);
              videoStore.setCurrentTime(display.toHis(startTime));
              viewState.isFirstSetStartTime = false;
            },
            false
          );
        }
      }

      viewState.isDisplayed = true; // 表示OK
    });

    return {
      env,
      token,
      isSp,
      userStore,
      videoStore,
      display,
      viewState,
      quillEditorRef,
      editorDialogRef,
      lessonsDialogRef,
      testDialogRef,
      canvasRefs,
      lessonDivCloseFun,
      lessonDivOpenFun,
      selectLesson,
      saveEditorText,
      showSpEditFun,
      hideEditFun,
      textChangeFun,
      saveNote,
      handleCommand,
      showLessonsListMenuFun,
      resetTestView,
      toggleTestValue,
      radioTestValue,
      sendTestValue,
      capturePaper,
      finishCapturePaper,
      slideAction,
      modalButtonFunction,
      fetchMaterial,
      finishModalContent,
      finishTestView,
      showTestDialog,
      countTitleByHalfWidthConversion,
    };
  },
  components: {
    QuillEditor,
    ElScrollbar,
    mydialog,
    lessonsDialog,
    editorDialog,
    testDialog,
    Carousel,
    Slide,
    Navigation,
  },
});
</script>

<style lang="scss">
.videoDiv {
  padding-right: 400px;
  overflow: hidden;
  transition: padding 0.5s linear;
  &.lessonDivClose {
    padding-right: 115px;
    @include mq(sm) {
      padding-right: 0;
    }
  }
  @include mq(sm) {
    padding-right: 0;
  }
  .videoPlayer {
    background: #000;
  }
  .lessonDIV {
    width: 400px;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    transition: width 0.5s linear;
    @include mq(sm) {
      display: none;
    }
    .lessonHeader {
      background: #fff;
      backdrop-filter: blur(100px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 100px;
      .titleDIV {
        height: 100%;
        align-items: center;
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        .title {
          width: 85px;
          text-align: left;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 18px;
          line-height: 70px;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $black;
        }
        .subTitle {
          font-weight: 500;
          font-size: 14px;
          line-height: 70px;
          letter-spacing: 0.08em;
          color: $black;
          opacity: 0.5;
        }
      }
      .slideDIV {
        min-width: 30px;
        width: 30px;
        min-height: 30px;
        height: 30px;
        margin-right: 50px;
      }
    }
    .el-scrollbar {
      background: transparent;
    }
    .el-scrollbar__bar.is-horizontal {
      display: none;
    }
    .el-scrollbar__bar.is-vertical {
      width: 5px;
      top: 5px;
      right: 5px;
    }
    .el-scrollbar__bar {
      border-radius: 2px;
      bottom: 5px;
      overflow: hidden;
    }
    .el-scrollbar__thumb {
      background-clip: content-box;
      background-color: $primary;
      box-shadow: 0px 20px 30px rgba(72, 159, 157, 0.25);
      opacity: 1;
    }
    .lessonContainer {
      overflow-y: auto;
      overflow-x: hidden;
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(100px);
      max-width: 400px;
      .over {
        background-color: #f0f3f8;
      }
      .lessonList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 100px;
        border-bottom: 1px solid $white;
        .no-title-time {
          display: flex;
          height: 100px;
          justify-content: flex-start;
          align-items: center;
          .no {
            align-self: flex-start;
            margin-top: 10px;
            width: 50px;
            height: 50px;
            margin-left: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 20px;
            line-height: 70px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.08em;
            color: $black;
          }
          .select {
            padding-left: 2px;
            align-self: flex-start;
            margin-top: 25.16px;
            background: $white;
            box-shadow: 0px 20px 30px rgba(72, 159, 157, 0.25);
            position: relative;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 26px;
            line-height: 70px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.08em;
            color: $black;
            .playProgress {
              position: absolute;
              height: 0px;
              border-bottom: 4px solid $secondary;
              left: 0px;
              bottom: 0px;
            }
          }
          .lessonTitle-time {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            margin-top: 25px;
            margin-left: 20px;
            margin-right: 18px;
            width: 208px;
            overflow: hidden;
            .lessonTitle {
              font-weight: 700;
              font-size: 20px;
              line-height: 20px;
              letter-spacing: 0.1em;
              white-space: nowrap;
              color: $black;
              text-align: left;
              max-width: 208px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .marquee {
              width: fit-content;
              max-width: fit-content;
              animation: flowing-pc 10s linear infinite;
            }
            @keyframes flowing-pc {
              100% {
                transform: translateX(-100%);
              }
            }
            .lessonTime {
              margin-top: 13px;
              display: flex;
              align-items: center;
              .playIcon {
                width: 8px;
                height: 10px;
                margin-right: 9.55px;
              }
              .playOverIcon {
                width: 12px;
                height: 12px;
                margin-right: 5.55px;
              }
              .lessonLength {
                font-weight: 500;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: 0.09em;
                color: rgba(89, 88, 87, 0.8);
              }
            }
          }
        }
        .playOption {
          width: 4px;
          height: 20px;
        }
      }
    }
  }
  &.lessonDivClose {
    .lessonDIV {
      width: 115px;
    }
  }
  position: relative;
  .editDIV {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 400px;
    background: white;
    transform: translateX(100%);
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    .editHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      .time {
        width: 80px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: 20px;
        background: $grey;
        font-weight: 700;
        font-size: 18px;
        line-height: 70px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.08em;
        color: $black;
        &::after {
          font-weight: 500;
          font-size: 14px;
          line-height: 70px;
          text-align: right;
          letter-spacing: 0.08em;
          color: $dark-grey;
          opacity: 0.6;
          content: attr(wordCnt) "/1000";
          position: absolute;
          width: 180px;
          left: 100px;
        }
      }
      .closeBtn {
        width: 80px;
        height: 80px;
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        .closeIcon {
          width: 16px;
          height: 16px;
        }
      }
    }
    .editorDIV {
      .ql-toolbar.ql-snow {
        border-left: none;
        border-right: none;
        border-top: none;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          height: 0px;
          width: 380px;
          top: 0;
          left: 20px;
          opacity: 0.5;
          border-top: 0.5px solid $black-50;
        }
        height: 80px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 30px;
        .ql-picker {
          margin-right: 0px;
        }
        button {
          margin-left: 40px;
          margin-right: 0px;
          width: 19px;
        }
        .ql-bold {
          padding-left: 0px;
        }
        .ql-italic {
          padding-left: 0px;
        }
        .ql-list {
          padding-left: 0px;
        }
        .ql-code-block {
          padding-left: 0px;
        }
      }
      .ql-container.ql-snow {
        border-top: 0.5px solid (15, 15, 17, 0.5);
        border-bottom: 0.5px solid (15, 15, 17, 0.5);
        border-left: none;
        border-right: none;
        height: 285px;
        padding: 10px;
        overflow: scroll;
      }
      .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        position: absolute;
        margin-top: -9px;
        right: -16px;
        top: 50%;
        width: 18px;
      }
      .ql-editor {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $dark-grey;
        padding-left: 30px;
        padding-right: 40px;
        padding-top: 20px;
      }
      .ql-editor.ql-blank::before {
        content: attr(data-placeholder);
        left: 30px;
        pointer-events: none;
        position: absolute;
        top: 0px;
        font-weight: 700;
        font-size: 18px;
        line-height: 70px;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $dark-grey;
        opacity: 0.2;
      }
      .ql-snow .ql-picker-label {
        cursor: pointer;
        display: inline-block;
        height: 100%;
        text-align: left;
        padding-left: 0px;
        padding-right: 2px;
        position: relative;
        width: 130%;
      }
    }
  }
  .showEdit {
    overflow: hidden;
    animation: showEdit 0.5s linear 1 forwards;
    transform: translateX(100%);
  }
  @keyframes showEdit {
    100% {
      transform: none;
    }
  }
  .hideEdit {
    overflow: hidden;
    animation: hideEdit 0.5s linear 1 forwards;
    transform: none;
  }
  @keyframes hideEdit {
    100% {
      transform: translateX(100%);
    }
  }
  .btnDIV {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
    .cancelBtn {
      width: 168px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $black;
      margin-right: 20px;
      .cancelText {
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: $dark-grey;
      }
    }
    .submitBtn {
      width: 172px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $black;
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
      .submitText {
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: $white;
      }
    }
  }
  .saved {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 123px;
    left: 20px;
    width: 350px;
    height: 80px;
    background: $secondary;
    box-shadow: 0px 20px 30px rgba(2, 218, 166, 0.25);
    z-index: 2;
    animation: saved 1s ease-out 1 forwards;
    .saveIcon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    .savedText {
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      color: $white;
    }
  }
  @keyframes saved {
    100% {
      opacity: 0;
      z-index: -1;
    }
  }
  // sp
  @include mq(sm) {
    flex-direction: column;
    display: flex;
  }
  .videoHeader {
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: $white;
    @include mq(sm) {
      display: flex;
    }
    .no-title-time {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 60px;
      .no {
        margin-left: 20px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.08em;
        color: $black;
        background: $white;
        box-shadow: 0px 20px 30px rgba(72, 159, 157, 0.25);
        position: relative;
        .playProgress {
          position: absolute;
          height: 0px;
          border-bottom: 4px solid #e24242;
          left: 0px;
          bottom: 0px;
        }
      }
      .lessonTitle-time-sp {
        display: flex;
        flex-direction: column;
        padding-top: 15px;
        height: 60px;
        margin-left: 20px;
        max-width: 190px;
        overflow: hidden;
        .lessonTitle {
          font-weight: 700;
          font-size: 16px;
          line-height: 100%;
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          color: $black;
          white-space: nowrap;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .marquee {
          width: fit-content;
          max-width: fit-content;
          animation: flowing-playing 10s linear infinite;
        }
        @keyframes flowing-playing {
          100% {
            transform: translateX(-100%);
          }
        }
        .lessonTime {
          margin-top: 6px;
          display: flex;
          align-items: center;
          .playIcon {
            width: 8px;
            height: 10px;
            margin-right: 8px;
          }
          .playOverIcon {
            width: 12px;
            height: 12px;
            margin-right: 5.55px;
          }
          .lessonLength {
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            letter-spacing: 0.09em;
            color: rgba(89, 88, 87, 0.8);
          }
        }
      }
    }
    .menuDIV {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .playOption {
        width: 4px;
        height: 20px;
        margin-right: 28px;
      }
      .listMenu {
        cursor: pointer;
        min-width: 60px;
        min-height: 60px;
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 24px;
          height: 19px;
        }
      }
    }
  }
  .videoContainer {
    background: #000;
  }
}
.el-dropdown-menu {
  border-radius: unset;
}
.dropdownCls {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  color: $black;
}
.dropdownCls:hover {
  background-color: unset !important;
  color: $primary !important;
}
.dropdown-icon {
  width: 20px;
  display: flex;
  justify-content: center;
}
.carousel {
  margin: 0 auto;
  overflow: hidden;
}
.slide {
  max-width: 800px;
}
.slide-sp {
  max-width: 100%;
  max-height: 500px;
}
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  background-color: #000;
  box-sizing: content-box;
  border: 5px solid white;
  transform: translateX(0);
}
.test-div {
  width: 100%;
  height: 440px;
  display: flex;
  justify-content: center;
	align-items: center;

  .test {
    max-width: 800px;
    width: 100%;
    background: white;
    padding: 50px;
    text-align: center;

    .title {
      font-size: 18px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .submit-area-button {
      margin: 10px auto;
      min-width: 80%;
      text-align: center;
      padding: 1rem;
      font-size: 18px;
      border: 2px solid $black;
      position: relative;

      &:hover {
        color: $primary;
        border: 2px solid $primary;
      }

      .swiper-button-next-btn-icon {
        position: absolute;
        top: 50%;
        width: 25px;
        height: 25px;
        right: 20px;
        transform: translateY(-50%);
      }
    }

    .message-section p {
      color: $dark-grey;
      margin-top: 50px;
    }
  }
}
.finish-dialog {
  @include mq(sm) {
    .html {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
}
.finish-message {
  color: $dark-grey;
  line-height: 50px;
  font-size: 26px;
  @include mq(sm) {
    font-size: 16px;
    line-height: 1.7;
  }
}
.next-message {
  margin-top: 25px;
  line-height: 40px;
  font-size: 18px;
  color: $dark-grey;
  @include mq(sm) {
    font-size: 12px;
    line-height: 1.7;
  }
}
</style>
