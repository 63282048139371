<template>
  <div class="notesDIV">
    <commTitle title="NOTES" subTitle="メモ" class="noteTitle"></commTitle>
    <div class="createNoteBtnDiv" v-if="!viewState.addFlg">
      <span
        :class="[viewState.editorUsingFlg ? 'createTextUnable' : 'createText']"
        >Create a new note at
        {{ display.toMemoTime(videoStore.currentTime) }}</span
      >
      <icon
        name="create-note-button"
        v-if="!viewState.editorUsingFlg"
        class="createBtn"
        @click="addNote"
        style="cursor: pointer;"
      />
      <icon
        name="create-note-button-unable"
        v-else
        class="createBtn"
        @click="addNote"
        :style="{
          cursor: !viewState.editorUsingFlg ? 'pointer' : 'not-allowed',
        }"
      />
    </div>
    <div class="createNoteBtnDivSP" v-if="!viewState.addFlg">
      <span class="createText"
        >Create a new note at
        {{ display.toMemoTime(videoStore.currentTime) }}</span
      >
      <icon
        name="create-note-button"
        class="createBtn"
        @click="showSpEdit('', '')"
        style="cursor: pointer;"
      />
    </div>

    <div class="addarea" v-if="viewState.addFlg">
      <CommonEditor
        :timeStamp="display.toMemoTime(videoStore.currentTime)"
        editValue=""
        id=""
        @cancelEditNote="cancelAddNote"
        @saveEditNote="saveAddNote"
      />
    </div>

    <!-- メモ一覧 -->
    <template v-if="viewState.isDisplayed">
      <div
        v-for="(item, index) of viewState.memos"
        :key="item.id"
      >
        <noteListItem
          :index="index"
          :note="item"
          :editorUsingFlg="viewState.editorUsingFlg"
          :editIndex="viewState.editIndex"
          :delIndex="viewState.delIndex"
          @editNote="editNote"
          @delConfirm="delConfirm"
          @showSpEdit="showSpEdit"
          @cancelEditNote="cancelEditNote"
          @saveEditNote="saveEditNote"
        />
      </div>
    </template>

    <div
      v-if="viewState.allMemos.length > viewState.memos.length"
      class="mottomiru"
    >
      <span class="textMoto">もっと見る</span>
      <icon
        name="sp-motomiru-icon"
        class="icon"
        @click="motomiru"
        style="cursor: pointer;"
      />
    </div>

    <!-- noteタブのSP用操作ダイアログ -->
    <editorDialog
      v-show="viewState.editorDialogVisible"
      ref="editorDialogRef"
      @editorText="editorTextSp"
    ></editorDialog>

    <deleteDialog
      :isShowAlert="viewState.showAlert"
      title="Delete Note"
      subTitle="メモ削除"
      :message="viewState.alertMessage"
      cancel="Cancel"
      submit="Delete Note"
      @cancelClick="cancelFunction"
      @submitClick="deleteFunction"
      @closeDialog="cancelFunction"
    />
  </div>
</template>

<script>
import commTitle from "@/components/CommonTitle/CommonTitle.vue";
import deleteDialog from "@/components/CommonDialog/CommonDialog.vue";
import CommonEditor from "@/components/CommonEditor/CommonEditor.vue";
import editorDialog from "@/views/view/editorDialog.vue";
import noteListItem from "@/components/Notes/NoteListItem.vue";
import { useStore } from "vuex";
import {
  defineComponent,
  reactive,
  ref,
  watch,
  nextTick,
  onMounted,
  onUnmounted,
  computed,
} from "vue";
import { useGlobalStore } from "@/store/global";
import display from "@/utils/display";
import http from "@/utils/http";
export default defineComponent({
  props: {
    memos: {
      default: [],
    },
    otherEditorUsingFlg: {
      default: false,
    },
    lectureCode: {
      default: "",
    },
    materialCode: {
      default: "",
    },
    materials: {
      default: [],
    },
  },
  setup(props) {
    const store = useStore();
    const isSp = computed(() => store.state.isSp);
    const token = store.getters.token;
    const { videoStore } = useGlobalStore();

    const viewState = reactive({
      materialCode: props.materialCode,
      lectureCode: props.lectureCode,
      editorUsingFlg: false,
      editorDialogVisible: false,
      adding: false,
      editing: false,
      addFlg: false,
      currentTitle: "01.Introduction",
      editContent: "",
      editIndex: -1,
      delIndex: -1,
      showAlert: false,
      alertMessage:
        "本当にメモを削除しますか？\n一度削除すると、\nメモは元に戻りません。",
      allMemos: [],
      memos: [],
      displayMemosCount: 3,
      materials: props.materials,
      response: {
        status: 0,
        data: {},
      },
      errors: [],
      deleteTargetKey: "",
      isDisplayed: false,
    });

    const editorDialogRef = ref();

    const motomiru = () => {
      viewState.memos = viewState.allMemos.slice(0, viewState.memos.length + 3);
      viewState.displayMemosCount = viewState.memos.length;
    };
    const editNote = (index) => {
      if (!store.state.editorUsing) {
        if (!viewState.editing && !viewState.adding) {
          viewState.editIndex = index;
          viewState.editContent = viewState.allMemos[index].content;
          viewState.editing = true;
          store.dispatch("calcIsEditorUsing", true);
          viewState.editorUsingFlg = true;
        }
      } else {
        return;
      }
    };
    const addNote = () => {
      if (!store.state.editorUsing) {
        if (!viewState.editing && !viewState.adding) {
          if (videoStore.player) {
            videoStore.player.pause(); // 停止
          }
          viewState.addFlg = true;
          viewState.adding = true;
          store.dispatch("calcIsEditorUsing", true);
          viewState.editorUsingFlg = true;
        }
      } else {
        return;
      }
    };
    const saveEditNote = async (memoKey, text) => {
      viewState.editIndex = -1;

      await http
        .put(
          `/api/materials/${viewState.materialCode}/memos/${memoKey}?key_type=code`,
          {
            memo: text,
            marked_time: videoStore.currentTime,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });

      // 更新時はリストビューのキーが同じなので再描画されないので再描画する
      viewState.isDisplayed = false;
      await fetchMemos();
      viewState.isDisplayed = true;

      viewState.editing = false;
      store.dispatch("calcIsEditorUsing", false);
      viewState.editorUsingFlg = false;
    };
    const cancelEditNote = () => {
      viewState.editIndex = -1;
      viewState.editing = false;
      store.dispatch("calcIsEditorUsing", false);
      viewState.editorUsingFlg = false;
    };
    const saveAddNote = async (id, text) => {
      viewState.addFlg = false;

      await http
        .post(
          `/api/materials/${viewState.materialCode}/memos?key_type=code`,
          {
            memo: text,
            marked_time: videoStore.currentTime,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });

      fetchMemos();

      viewState.adding = false;
      store.dispatch("calcIsEditorUsing", false);
      viewState.editorUsingFlg = false;
    };
    const cancelAddNote = () => {
      viewState.addFlg = false;
      viewState.adding = false;
      store.dispatch("calcIsEditorUsing", false);
      viewState.editorUsingFlg = false;
    };
    const delConfirm = (index, key) => {
      if (!store.state.editorUsing) {
        viewState.deleteTargetKey = key;
        viewState.showAlert = true;
        viewState.delIndex = index;
      } else {
        return;
      }
    };
    const cancelFunction = () => {
      viewState.showAlert = false;
      viewState.delIndex = -1;
    };
    const deleteFunction = async () => {
      if (!store.state.editorUsing) {
        viewState.showAlert = false;

        await http
          .delete(
            `/api/materials/${viewState.materialCode}/memos/${viewState.deleteTargetKey}?key_type=code`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            viewState.errors = error.response.data.errors;
            return error.response;
          });

        fetchMemos();

        viewState.delIndex = -1;
      } else {
        return;
      }
    };
    const showSpEdit = (memoKey, content) => {
      viewState.editorDialogVisible = true;
      nextTick(() => {
        editorDialogRef.value.init(memoKey, content);
      });
    };
    const editorTextSp = async (memoKey, text) => {
      if (memoKey == "") {
        //新規
        await http
          .post(
            `/api/materials/${viewState.materialCode}/memos?key_type=code`,
            {
              memo: text,
              marked_time: videoStore.currentTime,
            },
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            viewState.errors = error.response.data.errors;
            return error.response;
          });

        fetchMemos();
      } else {
        await http
          .put(
            `/api/materials/${viewState.materialCode}/memos/${memoKey}?key_type=code`,
            {
              memo: text,
              marked_time: videoStore.currentTime,
            },
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            return response;
          })
          .catch((error) => {
            viewState.errors = error.response.data.errors;
            return error.response;
          });

        // 更新時はリストビューのキーが同じなので再描画されないので再描画する
        viewState.isDisplayed = false;
        await fetchMemos();
        viewState.isDisplayed = true;
      }
    };
    const fetchMemos = () => {
      return http
        .get(`/api/lectures/${viewState.lectureCode}/memos?key_type=code`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const memos = response.data;

          viewState.memos = memos;
          viewState.allMemos = viewState.memos.slice(0);
          if (isSp.value && viewState.allMemos.length >= viewState.displayMemosCount) {
            viewState.memos = viewState.allMemos.slice(0, viewState.displayMemosCount);
          }

          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });
    };

    watch(
      () => props.otherEditorUsingFlg,
      (newVal) => {
        if (newVal > 1000) {
          viewState.editorUsingFlg = newVal;
        }
      }
    );
    watch(
      () => isSp.value,
      async () => {
        viewState.isDisplayed = false;
        await fetchMemos();
        viewState.isDisplayed = true;
      }
    );

    onMounted(async () => {
      await fetchMemos();
      viewState.editorUsingFlg = store.state.editorUsing;
      viewState.isDisplayed = true;
    });

    onUnmounted(() => {
      if (!viewState.otherEditorUsingFlg) {
        store.dispatch("calcIsEditorUsing", false);
      }
    });

    return {
      display,
      videoStore,
      viewState,
      editorDialogRef,
      motomiru,
      editNote,
      addNote,
      saveEditNote,
      cancelEditNote,
      saveAddNote,
      cancelAddNote,
      delConfirm,
      cancelFunction,
      deleteFunction,
      showSpEdit,
      editorTextSp,
      fetchMemos,
    };
  },
  components: {
    commTitle,
    deleteDialog,
    CommonEditor,
    editorDialog,
    noteListItem,
  },
});
</script>

<style lang="scss">
.notesDIV {
  max-width: 1316px;
  height: auto;
  display: flex;
  flex-direction: column;
  .noteTitle {
    margin-top: 65px;
    @include mq(sm) {
      margin-top: 60px;
    }
  }
  .createNoteBtnDiv {
    display: flex;
    justify-content: space-between;
    border: 0.5px solid $black-50;
    max-width: 1136px;
    height: 80px;
    align-items: center;
    margin-top: 40px;
    margin-left: 65px;
    margin-right: 115px;
    margin-bottom: 80px;
    @include mq(sm) {
      display: none;
    }
    .createText {
      margin-left: 30px;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      color: $black;
      @include mq(sm) {
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: $black;
        margin-left: 20px;
      }
    }
    .createTextUnable {
      margin-left: 30px;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      color: $grey;
    }
    .createBtn {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      @include mq(sm) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .createNoteBtnDivSP {
    display: none;
    justify-content: space-between;
    border: 0.5px solid $black-50;
    max-width: 1136px;
    height: 80px;
    align-items: center;
    margin-top: 40px;
    margin-left: 65px;
    margin-right: 115px;
    margin-bottom: 80px;
    @include mq(sm) {
      display: flex;
      height: 60px;
      margin-left: 30px;
      margin-right: 50px;
      margin-top: 15px;
      margin-bottom: 30px;
    }
    .createText {
      margin-left: 30px;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      color: $black;
      @include mq(sm) {
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: $black;
        margin-left: 20px;
      }
    }
    .createBtn {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      @include mq(sm) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .addarea {
    height: 440px;
    width: auto;
    margin-left: 60px;
    margin-right: 115px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .addBtnDIV {
      height: 140px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .addCancel {
        width: 200px;
        height: 60px;
        margin-top: 30px;
        border: 1px solid $black;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        .addCancelText {
          font-weight: 700;
          font-size: 18px;
          line-height: 70px;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $dark-grey;
        }
      }
      .addSave {
        width: 200px;
        height: 60px;
        background: $black;
        margin-top: 30px;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        .addSaveText {
          font-weight: 700;
          font-size: 18px;
          line-height: 70px;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $white;
        }
      }
    }
  }
  .mottomiru {
    display: none;
    @include mq(sm) {
      display: flex;
    }
    width: 355px;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    background: $black;
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    backdrop-filter: blur(10px);
    .textMoto {
      margin-left: 30px;
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: $white;
    }
    .icon {
      width: 30px;
      height: 30px;
      margin-right: 50px;
    }
  }
  .ql-preview {
    .ql-toolbar {
      display: none;
    }
    .ql-container {
      font-size: 16px;
      border: none;
    }
    .ql-editor {
      padding: 0;
    }
  }
}
</style>
