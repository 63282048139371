<template>
  <div class="mypage-note-item-div">
    <!-- PC -->
    <div
        class="noteItemPC"
        :class="{ bgColor: (index + 1) & (1 == 0x1) }"
    >
      <span class="time">{{ display.toMemoTime(note.marked_time) }}</span>
      <div class="note">
        <div class="noteItemTitle">
          <span class="titleText">{{ note.material_name }}</span>
          <div class="operation">
            <icon
              name="note-edit-icon"
              hoverName="note-edit-active-icon"
              :disable="editorUsingFlg"
              class="operationIcon"
              @click="editNote()"
              :style="{
                cursor: !editorUsingFlg ? 'pointer' : 'not-allowed',
              }"
              v-if="editIndex != index"
            />
            <icon
              name="note-edit-active-icon"
              style="cursor:not-allowed"
              class="operationIcon"
              v-else
            />
            <div class="splitLine" />
            <icon
              v-if="delIndex != index"
              name="note-del-icon"
              hoverName="note-del-active-icon"
              :disable="editorUsingFlg"
              class="operationIcon"
              @click="delConfirm()"
              :style="{
                cursor: !editorUsingFlg ? 'pointer' : 'not-allowed',
              }"
            />
            <icon
              v-else
              name="note-del-active-icon"
              :disable="editorUsingFlg"
              class="operationIcon"
            />
          </div>
        </div>
        <span class="noteContent ql-preview">
          <QuillEditor
            :content="note.memo"
            :readOnly="true"
            :options="{
              modules: {
                toolbar: [],
              },
            }"
            contentType="html"
          ></QuillEditor>
        </span>
        <div class="editarea" v-if="editIndex == index">
          <CommonEditor
            :timeStamp="display.toMemoTime(note.marked_time)"
            :editValue="note.memo"
            :memoKey="note.key"
            @cancelEditNote="cancelEditNote"
            @saveEditNote="saveEditNote"
            ref="commonEditorRef"
          />
        </div>
      </div>
    </div>

    <!-- SP -->
    <div
      class="noteItemSP"
      :class="{ bgColor: (index + 1) & (1 == 0x1) }"
    >
      <div class="noteItemTitle">
        <span class="time">{{ display.toMemoTime(note.marked_time) }}</span>
        <span class="titleText">{{ note.material_name }}</span>
      </div>
      <span
        :id="`noteContentSp${index}`"
        class="noteContent ql-preview"
      >
        <QuillEditor
          :content="note.memo"
          :readOnly="true"
          :options="{
            modules: {
              toolbar: [],
            },
          }"
          contentType="html"
        ></QuillEditor>
      </span>
      <div class="noteFooter">
        <div class="operation">
          <icon
            name="note-edit-icon"
            class="operationIcon"
            v-if="editIndex != index"
            @click="showSpEdit()"
            style="cursor: pointer;"
          />
          <icon
            name="note-edit-active-icon"
            class="operationIcon"
            v-if="editIndex == index"
          />
          <div class="splitLine" />
          <icon
            name="note-del-icon"
            class="operationIcon"
            @click="delConfirm()"
            style="cursor: pointer;"
          />
        </div>
        <template v-if="viewState.isDisplayReadMore">
          <div
            class="readMore"
            v-if="viewState.isHide"
            @click="changeIsHide"
            style="cursor: pointer;"
          >
            <span>read more</span><icon name="read-more"></icon>
          </div>
          <div
            class="readMore"
            v-else
            @click="changeIsHide"
            style="cursor: pointer;"
          >
            <span>read less</span><icon name="read-less"></icon>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import display from "@/utils/display";
import CommonEditor from "@/components/CommonEditor/CommonEditor.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { defineComponent, reactive, ref, onMounted } from "vue";

export default defineComponent({
  name: "noteListItemViews",

  components: {
    CommonEditor,
    QuillEditor,
  },
  props: ["index", "note", "editorUsingFlg", "editIndex", "delIndex"],
  emits: ["editNote", "delConfirm", "showSpEdit", "cancelEditNote", "saveEditNote"],
  setup(props, context) {
    const viewState = reactive({
      isHide: true,
      isDisplayReadMore: true
    });
    const commonEditorRef = ref();

    // method
    const editNote = () => {
      context.emit(
        "editNote",
        props.index
      );
    };

    const delConfirm = () => {
      context.emit(
        "delConfirm",
        props.index,
        props.note.key
      );
    };

    const showSpEdit = () => {
      context.emit(
        "showSpEdit",
        props.note.key,
        props.note.memo
      );
    };
    const cancelEditNote = () => {
      context.emit("cancelEditNote")
    };
    const saveEditNote = (memoKey, text) => {
      // CommonEditorから渡された値をそのまま渡す
      context.emit(
        "saveEditNote",
        memoKey,
        text
      );
    };

    const changeIsHide = () => {
      const noteContentSpDom = document.getElementById(`noteContentSp${props.index}`);
      if (viewState.isHide) {
        viewState.isHide = false;
        noteContentSpDom.classList.add('show-all');
        noteContentSpDom.classList.remove('show-partial');
      } else {
        noteContentSpDom.classList.add('show-partial');
        noteContentSpDom.classList.remove('show-all');
        viewState.isHide = true;
      }
    }
    
    onMounted(() => {
      const noteContentSpDom = document.getElementById(`noteContentSp${props.index}`);
      const memoHight = noteContentSpDom.scrollHeight;
      if (memoHight <= 90) {
        viewState.isDisplayReadMore = false;
        viewState.isHide = false;
        noteContentSpDom.classList.add('show-all');
      } else {
        viewState.isHide = true;
        viewState.isDisplayReadMore = true;
        noteContentSpDom.classList.add('show-partial');
      }
    });

    return {
      display,
      viewState,
      commonEditorRef,

      // method
      editNote,
      delConfirm,
      showSpEdit,
      cancelEditNote,
      saveEditNote,
      changeIsHide,
    };
  },
});
</script>

<style lang="scss">
.mypage-note-item-div {
  .noteItemPC {
    width: 100%;
    display: flex;
    @include mq(sm) {
      display: none;
    }
    flex-direction: row;
    .time {
      display: block;
      min-width: 115px;
      height: 100%;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 26px;
      line-height: 70px;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      color: $black;
      margin-left: 65px;
      margin-top: 12px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 115px;
        height: 4px;
        background-color: $black;
        top: -14px;
      }
    }
    .note {
      width: 100%;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $black;
        top: -0.5px;
        left: -115px;
      }
      .noteItemTitle {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 20px;
        .titleText {
          margin-left: 112px;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          color: $black;
        }
        .operation {
          margin-right: 115px;
          min-width: 72px;
          display: flex;
          justify-content: space-between;
          .splitLine {
            width: 0px;
            height: 20px;
            border-right: 1px solid $black;
          }
          .operationIcon {
            width: 16px;
            height: 16px;
          }
        }
      }
      .noteContent {
        display: block;
        margin-left: 112px;
        margin-right: 115px;
        margin-bottom: 50px;
        text-align: left;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $dark-grey;
      }
      .isBottom {
        margin-bottom: 65px;
      }
      .editarea {
        height: 440px;
        width: 100%;
        margin-left: -115px;
        margin-right: 115px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .editor {
          max-width: 1136px;
          height: 300px;
          border: 1px solid $black;
          background: $white;
        }
        .editBtnDIV {
          height: 140px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .editCancel {
            width: 200px;
            height: 60px;
            margin-top: 30px;
            border: 1px solid $black;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
            .editCancelText {
              font-weight: 700;
              font-size: 18px;
              line-height: 70px;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $dark-grey;
            }
          }
          .editSave {
            width: 200px;
            height: 60px;
            background: $black;
            margin-top: 30px;
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            .editSaveText {
              font-weight: 700;
              font-size: 18px;
              line-height: 70px;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $white;
            }
          }
        }
      }
    }
  }
  .noteItemSP {
    display: none;
    width: 100%;
    @include mq(sm) {
      display: flex;
    }
    flex-direction: column;
    .noteItemTitle {
      display: flex;
      flex-direction: row;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 1px;
        background-color: $black;
        top: -0.5px;
        left: 30px;
        right: 50px;
      }
      .time {
        display: block;
        height: 20px;
        line-height: 100%;
        margin-left: 30px;
        margin-top: 30px;
        margin-bottom: 15px;
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        color: $black;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 29.86px;
          height: 4px;
          background-color: $black;
          top: -32px;
        }
      }
      .titleText {
        margin-left: 20px;
        margin-top: 33px;
        margin-right: 50px;
        flex: 1;
        height: 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: $black;
        text-align: left;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
      }
    }
    .noteContent {
      margin-left: 30px;
      margin-right: 50px;
      margin-bottom: 15px;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: $dark-grey;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;

      &.show-all {
        height: auto;
        overflow: auto;
        text-overflow: unset;
        display: block;

        .ql-blank {
          max-height: none;
        }
      }

      &.show-partial {
        .ql-blank {
          max-height: 90px;
          overflow: hidden;

          &::before {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 30px;
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255, 0.9) 50%, #fff 100%);
          }
        }
      }
    }
    .noteFooter {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .operation {
        margin-left: 30px;
        min-width: 72px;
        display: flex;
        justify-content: space-between;
        .splitLine {
          width: 0px;
          height: 20px;
          border-right: 1px solid $black;
        }
        .operationIcon {
          width: 16px;
          height: 16px;
        }
      }
      .readMore {
        display: flex;
        align-items: center;
        margin-right: 50px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.1em;
          color: $secondary;
        }
        svg {
          margin-left: 10px;
          width: 16px;
          height: 16px;
        }
      }
    }
    .editarea {
      height: 100%;
      width: 100%;
      margin-left: -115px;
      margin-right: 115px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .editor {
        max-width: 1136px;
        height: 300px;
        border: 1px solid $black;
        background: $white;
      }
      .editBtnDIV {
        height: 140px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .editCancel {
          width: 200px;
          height: 60px;
          margin-top: 30px;
          border: 1px solid $black;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
          .editCancelText {
            font-weight: 700;
            font-size: 18px;
            line-height: 70px;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            color: $dark-grey;
          }
        }
        .editSave {
          width: 200px;
          height: 60px;
          background: $black;
          margin-top: 30px;
          box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: center;
          align-items: center;
          .editSaveText {
            font-weight: 700;
            font-size: 18px;
            line-height: 70px;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            color: $white;
          }
        }
      }
    }
    &.bgColor {
      background: rgba(240, 243, 248, 0.5);

      .noteContent {
        &.show-partial {
          .ql-blank {
            &::before {
              background: -webkit-linear-gradient(top, rgba(247, 249, 251, 0) 0%, rgba(247, 249, 251, 0.9) 50%, #F7F9FB 100%);
            }
          }
        }
      }
    }
  }
  .ql-preview {
    .ql-toolbar {
      display: none;
    }
    .ql-container {
      font-size: 16px;
      border: none;
    }
    .ql-editor {
      padding: 0;
    }
  }
}
</style>
