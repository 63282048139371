<template>
  <div class="NavigationBar">
    <div class="navigationLeft">
      <div
        class="navigation navigation-division-chapter"
        :class="{ isCheck: viewState.activeKey == 'chapter' }"
        @click="onClickItem('chapter')"
      >
        <span class="enText">Chapter</span>
        <span class="jpText">チャプター</span>
      </div>
      <div
        class="navigation navigation-division"
        :class="{ isCheck: viewState.activeKey == 'notes' }"
        @click="onClickItem('notes')"
      >
        <span class="enText">Notes</span>
        <span class="jpText">メモ</span>
      </div>
      <div
        class="navigation navigation-division"
        :class="{ isCheck: viewState.activeKey == 'keywords' }"
        @click="onClickItem('keywords')"
      >
        <span class="enText">Keywords</span>
        <span class="jpText">キーワード</span>
      </div>
    </div>
    <div class="share">
      <!-- <span class="shareText">Share</span>
      <icon name="pc-share-icon" class="shareIcon"></icon> -->
      <shareBtn />
    </div>
  </div>
</template>

<script>
import shareBtn from "@/components/CommonShare/CommonShare.vue";
import { defineComponent, reactive } from "vue";
export default defineComponent({
  setup(props, context) {
    const viewState = reactive({
      activeKey: "chapter",
    });

    const onClickItem = (key) => {
      viewState.activeKey = key;
      context.emit("onChangeActive", key);
    };

    return {
      viewState,
      onClickItem,
    };
  },
  components: {
    shareBtn,
  },
});
</script>

<style lang="scss" scoped>
.NavigationBar {
  height: 100px;
  max-width: 1316px;
  @include mq(sm) {
    width: 355px;
    height: 80px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .navigationLeft {
    max-width: 642px;
    width: 642px;
    height: 100px;
    @include mq(sm) {
      width: 355px;
      height: 80px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    .line {
      width: 0px;
      height: 20px;
      border-right: 1px solid $black;
    }
    .navigation {
      width: 100%;
      height: 100px;
      @include mq(sm) {
        height: 80px;
      }
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .enText {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0.08em;
        color: $black;
      }
      .jpText {
        font-weight: 500;
        font-size: 14px;
        @include mq(sm) {
          font-size: 12px;
        }
        margin-top: 8px;
        letter-spacing: 0.08em;
        color: $black;
        opacity: 0.5;
      }
    }
    .navigation-division-chapter {
      position: relative;
    }
    .navigation-division {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 20px;
        width: 1px;
        background-color: $black;
        top: 50%;
        transform: translateY(-50%);
        left: -0.5px;
      }
    }
    .isCheck {
      background: rgba(255, 255, 255, 0.6);
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $black;
        bottom: -1px;
      }
    }
  }
  .share {
    @include mq(sm) {
      display: none;
    }
    min-width: 83px;
    height: 20px;
    margin-right: 115px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .common-share {
      .common-share-container {
        .common-share-text {
          width: 49px;
          justify-content: center;
        }
        .common-share-icon {
          width: 18px;
        }
        .common-share-icon-active {
          width: 18px;
        }
      }
    }
  }
}
</style>
