<template>
  <div class="view">
    <div class="container">
      <div class="view-bg">
        <div class="container-bg"></div>
        <div class="sp-container-bg"></div>
        <icon name="bg-dot-circle" class="container-bottom-bg"></icon>
      </div>
      <div class="container-bg2">
        <icon name="view-bg2"></icon>
      </div>
      <div class="sp-container-bg2">
        <icon name="sp-view-bg2"></icon>
      </div>
      <template v-if="isLogin">
        <div class="container-bookmark" @click="addBookmark">
          <icon
            name="bookmark"
            class="bookmark"
            :class="viewState.isBookmarked && 'container-bookmark-shadow '"
          ></icon>
        </div>
        <div
          class="container-bookmark-active"
          v-if="viewState.isBookmarked"
          @click="removeBookmark"
        >
          <icon name="bookmark-active" class="bookmark-active"></icon>
        </div>
      </template>

      <template v-if="isLogin">
        <div
          v-if="
            viewState.lecture !== null && viewState.lecture.has_documents
          "
          class="container-bookmark2"
          @click="downloadZip"
        >
          <icon name="bookmark2" class="bookmark2"></icon>
          <icon name="bookmark2-hover" class="bookmark2-hover"></icon>
        </div>
        <div v-else class="container-bookmark2-disabled">
          <icon name="bookmark2" class="bookmark2"></icon>
        </div>
      </template>

      <div class="container-share">
        <!-- <div class="container-share-text">Share</div>
        <icon name="share-icon" class="container-share-icon"></icon>
        <icon name="sp-share-icon" class="sp-container-share-icon"></icon> -->
        <shareBtn />
      </div>

      <div class="content" v-if="viewState.isDisplayed">
        <div class="content-top">
          <div class="content-title">{{ viewState.chapter.name }}&nbsp;</div>
          <div v-if="viewState.chapter.published_at" class="content-date">
            <div class="content-date-label">チャプター公開日 :</div>
            <div class="content-date-val">
              {{ display.toDate(viewState.chapter.published_at) }}
            </div>
          </div>
          <div
            v-if="viewState.chapter.display_updated_at"
            class="content-update-date"
          >
            <div class="content-update-date-label">最終更新日 :</div>
            <div class="content-update-date-val">
              {{ display.toDate(viewState.chapter.display_updated_at) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="viewState.isDisplayed">
      <template v-if="isLogin">
        <videoPlayer
          :lectureCode="viewState.lectureCode"
          :chapters="viewState.chapters"
          :chapterCode="viewState.chapterCode"
          :materialCode="viewState.materialCode"
          :lecture="viewState.lecture"
          :material="viewState.material"
          :lessons="viewState.lessons"
          :selectIndex="viewState.lessonSelectIndex"
          @noteIsWriting="viewState.etitorUserFlg = true"
          @noteWriteOver="viewState.etitorUserFlg = false"
          @fetchMemos="fetchMemos"
        ></videoPlayer>
      </template>
    </div>
    <div class="navigationDIV">
      <navigationBar @onChangeActive="fun"></navigationBar>
    </div>
    <div v-if="viewState.isDisplayed" class="routePage">
      <div v-if="viewState.currentPage == 'chapter'">
        <div class="chapter-title-area">
          <commonTitle
            :title="'CHAPTER'"
            :subTitle="'チャプター'"
          ></commonTitle>
        </div>
        <div class="chapter-area">
          <chapterItem
            v-for="(item, index) in viewState.chapters"
            :key="index"
            :lecture="viewState.lecture"
            :chapter="item"
            :no="index + 1"
          ></chapterItem>
        </div>
      </div>
      <div v-if="viewState.currentPage == 'notes'">
        <template v-if="isLogin">
          <notes
            ref="notesRef"
            :memos="viewState.memos"
            :otherEditorUsingFlg="viewState.etitorUserFlg"
            :lectureCode="viewState.lectureCode"
            :materialCode="viewState.materialCode"
            :materials="viewState.materials"
          ></notes>
        </template>
      </div>
      <div v-if="viewState.currentPage == 'keywords'" class="keywords">
        <keywords :keyList="viewState.lecture.keywords"></keywords>
      </div>
    </div>
    <template v-if="viewState.isDisplayed && !isEnterprise">
      <relatedVideos
        v-if="viewState.relatedVideos.length > 0"
        :videos="viewState.relatedVideos"
        :levels="viewState.levels"
      ></relatedVideos>
    </template>
    <loginViewDialog
      v-if="!isLogin"
      :isShowLogin="viewState.isShowLogin"
      @closeDialog="closeDialog"
    />
    <!-- <editorDialog v-if="editorDialogVisible" ref="editorDialog"></editorDialog> -->
  </div>
</template>

<script>
import loginViewDialog from "@/views/view/loginViewDialog.vue";
import videoPlayer from "@/components/video/CommonVideo.vue";
import notes from "@/components/Notes/Notes.vue";
import navigationBar from "@/components/NavigationBar/NavigationBar.vue";
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";
import chapterItem from "@/views/lecture-detail/components/ChapterItem.vue";
import keywords from "@/components/KeyWords/KeyWords.vue";
import relatedVideos from "@/components/CommonRelatedVideos/RelatedVideos.vue";
import shareBtn from "@/components/CommonShare/CommonShare.vue";

import display from "@/utils/display";
import { ElLoading } from "element-plus";
import { defineComponent, reactive, onMounted, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import http from "@/utils/http";
import util from "@/utils/util";
export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const viewState = reactive({
      isDisplayed: false,
      lectureCode: route.params.lectureCode,
      chapterCode: route.params.chapterCode,
      materialCode: route.params.materialCode,
      isValidCodeSet: false,
      lecture: null,
      chapter: null,
      material: {},
      materials: [],
      lessons: [],
      lessonSelectIndex: 0,
      memos: [],
      response: {
        status: 0,
        data: {},
      },
      errors: [],
      currentPage: "chapter",
      relatedVideos: {},
      levels: [],
      isBookmarked: false,
      etitorUserFlg: false,
      isShowLogin: true,
      chapters: [],
    });
    const isLogin = computed(() => store.state.user.isLogin);
    const isEnterprise = computed(() => store.state.user.isEnterprise);
    const notesRef = ref();

    window.scrollTo(0, 0);

    const fun = (key) => {
      viewState.currentPage = key;
    };
    const downloadZip = () => {
      return http
        .get(
          `/api/lectures/${viewState.lectureCode}/documents/download?key_type=code`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const fileName = `ジッセン!DX_${viewState.lecture.name}_参考資料.zip`;
          const fileObject = new File([response.data], fileName, {
            type: "application/zip;",
          });
          const url = URL.createObjectURL(fileObject);
          const link = document.createElement("a");
          link.download = fileName;
          link.href = url;
          link.click();
          URL.revokeObjectURL(url);

          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });
    };
    const validateCodeSet = async () => {
      return await http
        .post("/api/code-set/validate", {
          lecture_code: viewState.lectureCode,
          chapter_code: viewState.chapterCode,
          material_time: viewState.materialCode,
        })
        .then((response) => {
          viewState.isValidCodeSet = response.data.is_valid;
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });
    };
    const fetchLevels = () => {
      return http
        .get("/api/levels")
        .then((response) => {
          viewState.levels = util.keyBy(response.data, "id");
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          throw error;
        });
    };
    const fetchLecturesRelatedLectureKeywords = async () => {
      return await http
        .get(
          `/api/lectures?lecture_id=${viewState.lecture.id}&keyword_related=1&order=1&res_ptn=1&count=30`
        )
        .then((response) => {
          viewState.relatedVideos = response.data;
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          throw error;
        });
    };
    const fetchLecture = async () => {
      return await http
        .get(`/api/lectures/${viewState.lectureCode}?key_type=code&res_ptn=1`)
        .then((response) => {
          viewState.lecture = response.data;
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          throw error;
        });
    };
    const fetchEnterpriseLecture = async () => {
      return await http
        .get(`/api/enterprise-lectures/${viewState.lectureCode}?key_type=code`)
        .then((response) => {
          viewState.lecture = response.data;
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          throw error;
        });
    };
    const fetchChapter = async () => {
      return await http
        .get(`/api/chapters/${viewState.chapterCode}?key_type=code`)
        .then((response) => {
          viewState.chapter = response.data;
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          throw error;
        });
    };
    const fetchChapters = async (lectureId) => {
      return await http
        .get(`/api/chapters/?lecture_id=${lectureId}&res_ptn=1`)
        .then((response) => {
          console.dir(response.data);
          viewState.chapters = response.data;

          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          throw error;
        });
    };
    const fetchMaterials = async () => {
      return await http
        .get(`/api/materials?chapter_id=${viewState.chapter.id}&res_ptn=1`)
        .then((response) => {
          viewState.materials = response.data;

          viewState.lessons = util.cloneDeep(viewState.materials);

          viewState.lessons.forEach((item, index) => {
            item.title = item.name;
            item.no = index + 1;
            item.showBtn = false; // テストを受けるボタン
            item.over = item.progress_rate === 100; // 完了アイコン

            if (item.code === viewState.materialCode) {
              viewState.lessonSelectIndex = index;
            }
          });

          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          throw error;
        });
    };
    const fetchMaterial = async () => {
      return await http
        .get(`/api/materials/${viewState.materialCode}?key_type=code&res_ptn=1`)
        .then((response) => {
          console.dir(response.data);
          viewState.material = response.data;
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          throw error;
        });
    };
    const fetchMemos = async () => {
      notesRef.value.fetchMemos();
    };
    const addBookmark = async () => {
      if (viewState.isBookmarked) {
        return;
      }

      return await http
        .post(`/api/lectures/${viewState.lectureCode}/favorites?key_type=code`)
        .then((response) => {
          viewState.isBookmarked = true;
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });
    };
    const removeBookmark = async () => {
      if (!viewState.isBookmarked) {
        return;
      }

      return await http
        .delete(`/api/lectures/${viewState.lectureCode}/favorites?key_type=code`)
        .then((response) => {
          viewState.isBookmarked = false;
          return response;
        })
        .catch((error) => {
          viewState.errors = error.response.data.errors;
          return error.response;
        });
    };
    const closeDialog = () => {
      router.push(`/lecture-detail/${route.params.lectureCode}`);
    };
    const init = async () => {
      await validateCodeSet();
      if (!viewState.isValidCodeSet) {
        // エラーの場合は講座一覧を表示
        loading.close();
        router.push({
          name: "lecture",
        });
        return;
      }

      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングで講座情報を取得する
        console.log("skip on-mount process");
        return;
      }

      // レクチャー一覧取得
      try {
        if (isEnterprise.value) {
          await Promise.all([fetchEnterpriseLecture(), fetchChapter()]);
        } else {
          fetchLevels();
          await Promise.all([fetchLecture(), fetchChapter()]);
          fetchLecturesRelatedLectureKeywords();
        }
        await Promise.all([fetchMaterials(), fetchMaterial()]);

        await fetchChapters(viewState.lecture.id);
      } catch (error) {
        console.log(error.response.status);
        if (error.response.status == 403 || error.response.status == 404) {
          // 403,404はnot found画面
          loading.close();
          router.push({
            name: "notfound"
          });
          return;
        }
        // エラーの場合は一覧画面
        loading.close();
        router.push({
          name: "lecture",
        });
        return;
      }
      viewState.isBookmarked = viewState.lecture.is_favorite;
      viewState.isDisplayed = true; // 表示OK
    };
    const reInit = async () => {
      console.log("re-init");
      await init();
    };

    onMounted(async () => {
      await init();
    });

    onBeforeRouteUpdate(async (to, from) => {
      viewState.lectureCode  = to.params.lectureCode;
      viewState.chapterCode  = to.params.chapterCode;
      viewState.materialCode = to.params.materialCode;

      viewState.isDisplayed = false;

      loading = ElLoading.service({
        lock: true,
        text: "Loading...",
        background: "#fff",
      });

      window.scrollTo(0, 0);

      await validateCodeSet();
      if (!viewState.isValidCodeSet) {
        // エラーの場合は講座一覧を表示
        loading.close();
        router.push({
          name: "lecture",
        });
        return;
      }
      try {
        if (isEnterprise.value) {
          await Promise.all([fetchEnterpriseLecture(), fetchChapter()])
        } else {
          await Promise.all([fetchLecture(), fetchChapter()]);
        }
        await Promise.all([fetchMaterials(), fetchMaterial()]);

        await fetchChapters(viewState.lecture.id);
      } catch (error) {
        if (error.response.status == 403 || error.response.status == 404) {
          // 403,404はnot found画面
          loading.close();
          router.push({
            name: "notfound"
          });
          return;
        }
        // 他エラーの場合は一覧画面
        loading.close();
        router.push({
          name: "lecture",
        });
        return;
      }
      viewState.isDisplayed = true;
    })

    let loading = ElLoading.service({
      lock: true,
      text: "Loading...",
      background: "#fff",
    });
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        await reInit();
      }
    );
    watch(
      () => viewState.isDisplayed,
      () => {
        if (viewState.isDisplayed) {
          loading.close();
        }
      }
    );

    return {
      display,
      isLogin,
      viewState,
      isEnterprise,
      notesRef,
      fun,
      addBookmark,
      removeBookmark,
      downloadZip,
      fetchMemos,
      closeDialog,
    };
  },
  components: {
    loginViewDialog,
    navigationBar,
    commonTitle,
    chapterItem,
    notes,
    videoPlayer,
    keywords,
    relatedVideos,
    shareBtn,
    ElLoading,
  },
});
</script>

<style lang="scss" scoped>
.view {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1366px;
  margin: 0 auto;
  .container {
    position: relative;
    .view-bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 1295px;
      z-index: -1;
      .container-bg {
        position: absolute;
        max-width: 1366px;
        width: 100%;
        height: 1295px;
        left: 0;
        top: 0;
        background-image: url(../../assets/img/view-bg1.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
      .container-bottom-bg {
        position: absolute;
        left: -310px;
        bottom: -310px;
        width: 620px;
        height: 620px;
        z-index: -1;
      }
      .sp-container-bg {
        display: none;
      }
    }
    .container-bg2 {
      width: 1251px;
      height: 400px;
      position: absolute;
      z-index: -1;
      right: 111px;
      top: -0.5px;
      svg {
        width: 1251px;
        height: 400px;
        z-index: 1;
      }
    }
    .sp-container-bg2 {
      display: none;
      z-index: -1;
    }
    .container-bookmark2 {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      position: absolute;
      right: 113px;
      top: 100px;
      @media (any-hover: hover) {
        &:hover {
          .bookmark2 {
            display: none;
          }
          .bookmark2-hover {
            display: block;
          }
        }
      }
      .bookmark2 {
        width: 24px;
        height: 26px;
      }
      .bookmark2-hover {
        display: none;
        width: 24px;
        height: 26px;
      }
    }
    .container-bookmark2-disabled {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      position: absolute;
      right: 113px;
      top: 100px;
      background: #efefef;
      opacity: 0.2;
      .bookmark2 {
        width: 24px;
        height: 26px;
        z-index: -1;
      }
    }
    .container-share {
      display: none;
    }
    .container-bookmark {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      position: absolute;
      right: 212px;
      top: 100px;
      backdrop-filter: blur(100px);
      .bookmark {
        width: 18px;
        height: 26px;
      }
    }
    .container-bookmark-shadow {
      box-shadow: 0px 20px 30px rgba(54, 131, 245, 0.25);
    }
    .container-bookmark-active {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      position: absolute;
      right: 212px;
      top: 100px;
      backdrop-filter: blur(100px);
      .bookmark-active {
        width: 19px;
        height: 27px;
        box-shadow: inset 0px 20px 12px rgba(54, 131, 245, 0.25),
          0px 20px 30px rgba(54, 131, 245, 0.25);
      }
    }
    .content {
      margin-left: 115px;
      display: flex;
      flex-direction: column;
      z-index: -1;
      .content-top {
        min-height: 299px;
        .content-title {
          margin-top: 100px;
          font-family: "Roboto Condensed", "Noto Sans JP", sans-serif;
          font-weight: 700;
          font-size: 40px;
          line-height: 70px;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: $black;
          margin-right: 315px;
          text-align: left;
        }
        .content-date {
          display: flex;
          align-items: center;
          margin-top: 22px;
          .content-date-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: $black;
          }
          .content-date-val {
            margin-left: 4px;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.08em;
            color: rgba(0, 0, 0, 0.8);
            padding-bottom: 1px;
          }
        }
        .content-update-date {
          display: flex;
          align-items: center;
          margin-top: 7px;
          .content-update-date-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: $black;
          }
          .content-update-date-val {
            margin-left: 4px;
            padding-bottom: 1px;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.08em;
            color: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .content-link {
        margin-right: 320px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        .content-link-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-width: 312px;
          width: 312px;
          height: 100px;
          background: rgba(255, 255, 255, 0.3);
          cursor: pointer;
          .content-link-item-title {
            margin-left: 54px;
            display: flex;
            align-items: center;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 18px;
            line-height: 70px;
            letter-spacing: 0.08em;
            color: $black;
          }
          .content-link-item-detail {
            margin-left: 10px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 70px;
            letter-spacing: 0.08em;
            color: $black;
            opacity: 0.5;
          }
          .content-link-item-icon {
            margin-right: 50px;
            width: 30px;
            height: 30px;
          }
          .content-link-item-split-line {
            width: 0px;
            height: 20px;
            border-right: 1px solid $black;
          }
        }
      }
    }
    @include mq(sm) {
      .view-bg {
        height: 1057px;
        .container-bg {
          display: none;
        }
        .sp-container-bg {
          display: block;
          position: absolute;
          width: 100%;
          height: 1057px;
          z-index: 1;
          left: 0;
          top: 0;
          background-image: url(../../assets/img/sp-detail-bg1.png);
          background-repeat: no-repeat;
          background-size: cover;
        }
        .container-bottom-bg {
          position: absolute;
          z-index: -1;
          left: -141px;
          bottom: -141px;
          width: 282px;
          height: 282px;
        }
      }
      .container-bg2 {
        display: none;
      }
      .sp-container-bg2 {
        display: block;
        width: 345.43px;
        height: 480.65px;
        position: absolute;
        right: 30px;
        top: -1px;
        svg {
          width: 345.43px;
          height: 480.65px;
          z-index: 1;
        }
      }
      .container-bookmark2 {
        width: 60px;
        height: 60px;
        right: 30px;
        top: 240px;
        .bookmark2 {
          width: 18.57px;
          height: 26px;
        }
        .bookmark2-hover {
          width: 18.57px;
          height: 26px;
        }
      }
      .container-bookmark2-disabled {
        width: 60px;
        height: 60px;
        right: 30px;
        top: 240px;
      }
      .container-bookmark {
        width: 60px;
        height: 60px;
        right: 90px;
        top: 240px;
        .bookmark {
          width: 16px;
          height: 24px;
        }
      }
      .container-bookmark-active {
        width: 60px;
        height: 60px;
        right: 90px;
        top: 240px;
        .bookmark-active {
          width: 16px;
          height: 24px;
        }
      }
      .container-bookmark2-disabled {
        width: 60px;
        height: 60px;
        right: 30px;
        top: 240px;
        background: #efefef;
        opacity: 0.2;
        .bookmark2 {
          width: 18.57px;
          height: 26px;
          z-index: -1;
        }
      }
      .container-share {
        display: flex;
        position: absolute;
        top: 263px;
        left: 50px;
        width: unset;
        .common-share {
          .common-share-container {
            justify-content: flex-start;
            .common-share-icon {
              margin-left: 8px;
              width: 18px;
            }
            .common-share-icon-active {
              margin-left: 8px;
              width: 18px;
            }
          }
        }
      }
      .content {
        margin-left: 50px;
        margin-bottom: 60px;
        .content-top {
          height: 299px;
          .content-title {
            margin-top: 80px;
            margin-right: 50px;
            font-family: "Roboto Condensed", "Noto Sans JP", sans-serif;
            font-weight: 700;
            font-size: 30px;
            line-height: 50px;
            display: flex;
            align-items: center;
            letter-spacing: 0.05em;
            color: $black;
          }
          .content-date {
            margin-top: 24px;
            .content-date-label {
              font-weight: 500;
              font-size: 12px;
              line-height: 100%;
              color: $black;
            }
            .content-date-val {
              margin-left: 4px;
              padding-bottom: 0px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.8;
            }
          }
          .content-update-date {
            margin-top: 7px;
            .content-update-date-label {
              font-weight: 500;
              font-size: 12px;
              line-height: 100%;
              color: $black;
            }
            .content-update-date-val {
              margin-left: 4px;
              padding-bottom: 0px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.8;
            }
          }
        }
        .content-link {
          width: 245px;
          height: 180px;
          background: rgba(255, 255, 255, 0.3);
          .content-link-item {
            min-width: 245px;
            width: 245px;
            height: 60px;
            position: relative;
            .content-link-item-split-line {
              position: absolute;
              top: 0px;
              left: 30px;
              width: 185px;
              height: 0px;
              border-right: 0px;
              border-bottom: 1px solid $black;
            }
            .content-link-item-title {
              margin-left: 30px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 16px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
            }
            .content-link-item-detail {
              font-weight: 500;
              font-size: 12px;
              line-height: 100%;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.5;
              white-space: nowrap;
            }
            .content-link-item-icon {
              margin-right: 30px;
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
  .navigationDIV {
    margin-left: 50px;
    z-index: 1;
    @include mq(sm) {
      margin-top: 0px;
      margin-left: 20px;
    }
  }
  .routePage {
    margin-top: 2px;
    margin-left: 50px;
    background: linear-gradient(
      to left top,
      rgba(255, 255, 255, 0.95),
      rgba(255, 255, 255, 1)
    );
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    @include mq(sm) {
      margin-top: 2px;
      margin-left: 20px;
    }
    .keywords {
      margin-top: 65px;
    }
  }
  .chapter-title-area {
    z-index: 2;
    margin-top: 68px;
    margin-left: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mq(sm) {
      margin-left: 20px;
      margin-top: 60px;
    }
    .chapterDetail {
      min-width: 456px;
      width: 456px;
      height: 100px;
      margin-right: 115px;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;
      color: $dark-grey;
      text-align: left;
      margin-left: 65px;
      @include mq(sm) {
        min-width: unset;
        margin-left: 30px;
        margin-top: 30px;
        margin-right: 50px;
        width: 295px;
        height: 150px;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        color: $dark-grey;
      }
    }
  }
  .chapter-area {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-left: 115px;
    margin-right: 115px;
    @include mq(sm) {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
  .detail-teacher {
    margin-left: 50px;
    margin-top: 65px;
    margin-right: 115px;
    @include mq(sm) {
      margin-left: 20px;
      margin-top: 60px;
    }
  }
}
</style>
