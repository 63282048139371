<template>
  <div class="mask" v-if="state.isShowDialogs">
    <div class="dialogDIV">
      <div class="dialogTitle">
        <div class="titleDIV">
          <icon name="logo" class="logo"></icon>
        </div>
        <div class="closeBtn" @click="closeDialog">
          <icon name="batu-icon" class="closeIcon" />
        </div>
      </div>
      <div class="dialog-body">
        <div class="login-form">
          <div
            class="error-message"
            v-if="store.state.user.login.errors.message"
          >
            <icon name="error" class="icon" />{{
              store.state.user.login.errors.message
            }}
          </div>
          <div
            class="error-message"
            v-for="(error, index) in store.state.user.login.errors.email"
            :key="index"
          >
            <icon name="error" class="icon" />{{ error }}
          </div>
          <div
            class="error-message"
            v-for="(error, index) in store.state.user.login.errors.password"
            :key="index"
          >
            <icon name="error" class="icon" />{{ error }}
          </div>
          <div class="input-mail">
            <input
              v-model="state.email"
              :class="{ error: state.emailError }"
              type="text"
              placeholder="メールアドレス"
            />
          </div>
          <div class="input-pass">
            <input
              v-model="state.password"
              :class="{ error: state.passwordError }"
              type="password"
              placeholder="パスワード"
            />
          </div>
        </div>
        <div class="login-sub">
          <div class="autologin">
            <input
              type="checkbox"
              name=""
              id="autologin"
              v-model="state.auto"
            /><label for="autologin">自動ログインを有効にする</label>
          </div>
          <div class="forget">
            パスワードを忘れた方は<router-link
              to="/reset-password/send"
              @click="closeDialog"
              >こちら</router-link
            >
          </div>
        </div>
        <div class="btnDIV">
          <button class="submitBtn" @click="submitClick">
            <span class="submitText">ログイン</span>
          </button>
          <button
            class="submitBtn submitBtn--signup"
            @click="$router.push('/apply')"
          >
            <span class="submitText">新規登録</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default defineComponent({
  props: ["isShowLogin", "submit"],
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      email: "",
      password: "",
      auto: false,
      emailError: false,
      passwordError: false,
      submitDisabled: true,
      isShowDialogs: props.isShowLogin,
    });
    const submitClick = async () => {
      await store.dispatch("setLogin", {
        email: state.email,
        password: state.password,
        auto: state.auto,
      });
      await store.dispatch("login");
      checkError();
      if (
        !state.emailError &&
        !state.passwordError &&
        !store.state.user.login.errors.message
      ) {
        state.isShowDialogs = false;
        document.body.style.overflow = "";
        router.go({ path: router.currentRoute.path, force: true });
      }
    };
    const checkError = () => {
      state.emailError = false;
      state.passwordError = false;
      if (
        store.state.user.login.errors.email &&
        store.state.user.login.errors.email.length
      ) {
        state.emailError = true;
      }
      if (
        store.state.user.login.errors.password &&
        store.state.user.login.errors.password.length
      ) {
        state.passwordError = true;
      }
    };
    const closeDialog = () => {
      state.isShowDialogs = false;
      document.body.style.overflow = "";
      context.emit("closeDialog");
      state.email = "";
      state.password = "";
      store.dispatch("clearLogin");
    };
    watch(
      () => props.isShowLogin,
      () => {
        state.isShowDialogs = props.isShowLogin;
        if (props.isShowLogin) {
          document.body.style.overflow = "hidden";
        }
      }
    );
    return {
      store,
      state,
      submitClick,
      checkError,
      closeDialog,
    };
  },
});
</script>

<style lang="scss" scoped>
.dialogDIV {
  max-width: 600px;
  height: fit-content;
  background: $white;
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  @include mq(sm) {
    min-width: 315px;
    width: 315px;
    display: flex;
    flex-direction: column;
  }
  .dialogTitle {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 80px;
    justify-content: space-between;
    position: relative;
    @include mq(sm) {
      height: 60px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.5px;
      border-bottom: 0.5px solid $black-50;
      bottom: -0.5px;
      @include mq(sm) {
        border-bottom: 1px solid rgba(15, 15, 17, 1);
        bottom: -1px;
      }
    }
    .titleDIV {
      height: 100%;
      .logo {
        margin-left: 50px;
        width: 90px;
        height: 100%;
        @include mq(sm) {
          margin-left: 20px;
        }
      }
    }
    .closeBtn {
      width: 80px;
      height: 80px;
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include mq(sm) {
        width: 60px;
        height: 60px;
        min-width: 60px;
      }
      .closeIcon {
        width: 16px;
        height: 16px;
      }
    }
  }
  .dialog-body {
    padding: 50px;
    @include mq(sm) {
      padding: 30px 20px 20px;
    }

    .login-form {
      .error-message {
        margin-bottom: 20px;
      }
      .input-mail {
        margin-bottom: 30px;
      }
      .input-pass {
        margin-bottom: 20px;
      }
    }
    .login-sub {
      display: flex;
      margin-bottom: 30px;
      font-size: 12px;
      justify-content: space-between;
      @include mq(sm) {
        display: block;
        .autologin {
          margin-bottom: 24px;
          line-height: 20px;
        }
      }
    }
    .btnDIV {
      display: flex;
      flex-direction: row;
      justify-content: center;
      @include mq(sm) {
        flex-direction: column;
      }
      .submitBtn {
        flex-grow: 1;
        height: 60px;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        background: $black;
        cursor: pointer;
        @include mq(sm) {
          width: 100%;
          margin-right: 0;
        }
        &--signup {
          position: relative;
          width: 30%;
          background: #db3535;
          margin-right: 0;
          margin-left: 20px;
          @include mq(sm) {
            width: 100%;
            margin-left: 0;
          }
          &:before {
            content: "";
            width: 1px;
            height: 20px;
            position: absolute;
            left: -20px;
            top: 50%;
            background-color: $black;
            transform: translateY(-50%);
            @include mq(sm) {
            content: none;
          }
          }
        }
        &:disabled {
          background-color: #f0f3f8;
          cursor: auto;
        }
        .submitText {
          font-weight: 700;
          font-size: 18px;
          line-height: 70px;
          letter-spacing: 0.08em;
          color: $white;
          @include mq(sm) {
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.1em;
            color: $white;
          }
        }
      }
    }
  }
}
</style>
