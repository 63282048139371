<template>
  <div class="chapterItem" :class="viewState.isExpand ? 'chapterItem-expend' : ''">
    <div class="chapter-num">
      {{ no.toString().padStart(2, '0') }}
    </div>
    <div
      class="chapter-btn"
      :class="viewState.isExpand ? 'chapter-btn-isExpand' : ''"
      @click="chapterIconClick"
    >
      <div v-if="viewState.isExpand" class="chapter-reduce-btn">
        <icon name="chapter-reduce-btn" class="chapter-reduce-btn-icon"></icon>
      </div>
      <icon
        v-else
        name="chapter-plus-btn"
        class="chapter-plus-btn"
      ></icon>
    </div>
    <div class="chapter-center">
      <div class="chapter-title">
        {{ chapter.name }}
      </div>
      <div class="chapter-status">
        <template v-if="isLogin && 'progress_rate' in chapter">
          <template v-if="chapter.progress_rate == 100">
            <icon name="chapter-status2" class="chapter-status2-icon"></icon>
            <div class="chapter-status2-text">Watched</div>
          </template>
          <template v-else-if="chapter.is_attendanced">
            <icon name="chapter-status1" class="chapter-status1-icon"></icon>
            <div class="chapter-status1-text">Continue Watching</div>
          </template>
          <template v-else>
            <icon name="chapter-status0" class="chapter-status0-icon"></icon>
            <div class="chapter-status0-text">Unwatched</div>
          </template>
        </template>
      </div>
    </div>
    <div class="chapter-detail" v-if="viewState.isExpand">
      <div class="chapter-detail-box">
        <div class="chapter-detail-box-item">
          <div class="chapter-detail-box-item-label">テスト</div>
          <div class="chapter-detail-box-item-colon">:&nbsp;</div>
          <div class="chapter-detail-box-item-value">{{ chapter.has_test ? '有' : '無' }}</div>
        </div>
        <div class="chapter-detail-box-item">
          <div class="chapter-detail-box-item-label">レッスン</div>
          <div class="chapter-detail-box-item-colon">:&nbsp;</div>
          <div class="chapter-detail-box-item-value">{{ chapter.materials_count }}</div>
        </div>
        <div class="chapter-detail-box-item">
          <div class="chapter-detail-box-item-label">視聴時間</div>
          <div class="chapter-detail-box-item-colon">:&nbsp;</div>
          <div class="chapter-detail-box-item-value">{{ display.timestampToHm(chapter.total_attendance_time) }}</div>
        </div>
      </div>
      <div
        class="chapter-detail-btn"
        @click="seeVideo"
      >
        <div class="chapter-detail-btn-text">受講を開始する</div>
        <icon name="chapter-detail-btn" class="chapter-detail-btn-icon"></icon>
      </div>
    </div>
  </div>
</template>

<script>
import display from "@/utils/display";
import http from "@/utils/http";
import { defineComponent, reactive, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  props: ['lecture', 'chapter', 'no'],
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const isLogin = computed(() => store.state.user.isLogin);
    const viewState = reactive({
      isExpand: true,
      material: {}
    });
    
    // method
    const chapterIconClick = () => {
      viewState.isExpand = !viewState.isExpand;
    };
    const seeVideo = () => {
      if (viewState.material.code !== undefined) {
        router.push(`/view/${props['lecture'].code}/${props['chapter'].code}/${viewState.material.code}`);
        return;
      }
    };
    const fetchMaterial = async () => {
      return await http
        .get(`/api/materials?chapter_id=${props['chapter'].id}&res_ptn=1`)
        .then((response) => {
          console.dir(response.data);
          if (response.data.length > 0) {
            viewState.material = response.data[0];
          }
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    }

    onMounted(async () => {
      await fetchMaterial();

      viewState.isDisplayed = true; // 表示OK
    });

    return {
      display,
      viewState,
      isLogin,

      // method
      chapterIconClick,
      seeVideo,
    };
  },
});
</script>

<style lang="scss" scoped>
.chapterItem {
  display: flex;
  flex-direction: column;
  max-width: 1136px;
  width: 100%;
  border-top: 0.5px solid $black-50;
  margin-bottom: 50px;
  position: relative;
  &::before {
    position: absolute;
    width: 30px;
    height: 4px;
    content: "";
    left: 0;
    top: -2px;
    background-color: $black;
  }
  .chapter-num {
    position: absolute;
    margin-top: 26px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 26px;
    line-height: 70px;
    letter-spacing: 0.08em;
    color: $black;
  }
  .chapter-btn {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 36px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .chapter-reduce-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      background: $primary;
      box-shadow: 0px 20px 30px rgba(54, 131, 245, 0.25);
      .chapter-reduce-btn-icon {
        width: 20px;
        height: 40px;
      }
    }
    .chapter-plus-btn {
      height: 20px;
      width: 20px;
    }
  }
  .chapter-btn-isExpand {
    right: 0px;
    top: 36px;
  }
  .chapter-center {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 100px;
    margin-left: 227px;
    @media screen and (max-width: 899px) {
      margin-left: 127px;
    }
    @media screen and (max-width: 750px) {
      margin-left: 60px;
    }
    .chapter-title {
      font-weight: 700;
      font-size: 26px;
      line-height: 160%;
      letter-spacing: 0.1em;
      color: $black;
      margin-top: 40px;
      text-align: left;
    }
    .chapter-status {
      display: flex;
      align-items: center;
      margin-top: 10px;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
      .chapter-status0-text {
        font-weight: 700;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $dark-grey;
        opacity: 0.5;
        @media screen and (max-width: 750px) {
          font-weight: 700;
          font-size: 12px;
          line-height: 100%;
          letter-spacing: 0.1em;
          color: $dark-grey;
        }
      }
      .chapter-status1-text {
        font-weight: 700;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $secondary;
        @media screen and (max-width: 750px) {
          font-weight: 700;
          font-size: 12px;
          line-height: 100%;
          letter-spacing: 0.1em;
          color: $secondary;
        }
      }
      .chapter-status2-text {
        font-weight: 700;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        color: $black;
        @media screen and (max-width: 750px) {
          font-weight: 700;
          font-size: 12px;
          line-height: 100%;
          letter-spacing: 0.1em;
          color: $black;
        }
      }
    }
  }
  .chapter-content {
    margin-right: 0px;
    margin-top: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    color: $dark-grey;
    text-align: left;
  }
  .chapter-detail {
    display: flex;
    margin-left: 0px;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: flex-end;
    .chapter-detail-box {
      max-width: 682px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .chapter-detail-box-item {
        width: 227px;
        height: 60px;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.1em;
        color: rgba(89, 88, 87, 0.8);
        position: relative;
        background: $grey;
        .chapter-detail-box-item-label {
          margin-left: 64px;
        }
        .chapter-detail-box-item-value {
          font-weight: 700;
          color: $black;
        }
        &::before {
          position: absolute;
          left: 0px;
          top: 20px;
          height: 20px;
          border-right: 0.5px solid $black-50;
          content: "";
        }
        &:first-child {
          &::before {
            content: none;
          }
        }
      }
    }
    .chapter-detail-btn {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 227px;
      height: 60px;
      align-items: center;
      background: $black;
      position: relative;
      z-index: 2;
      .chapter-detail-btn-text {
        font-weight: 700;
        font-size: 18px;
        letter-spacing: 0.08em;
        color: $white;
        margin-left: 30px;
        z-index: 2;
      }
      .chapter-detail-btn-icon {
        width: 40px;
        height: 10px;
        margin-right: 15px;
        z-index: 2;
      }
      @keyframes chapter-detail-btn-keyframe {
        from {
          width: 0px;
        }
        to {
          width: 100%;
        }
      }
      &:hover {
        &::before {
          content: "";
          left: 0;
          height: 60px;
          position: absolute;
          background: $primary;
          z-index: 0;
          animation: chapter-detail-btn-keyframe 0.3s linear 0s 1 forwards;
        }
        &::after {
          content: "";
          left: 0;
          height: 60px;
          position: absolute;
          background: $black;
          z-index: 1;
          animation: chapter-detail-btn-keyframe 0.3s linear 0.6s 1 forwards;
        }
      }
    }
  }
  @include mq(sm) {
    width: 100%;
    border-top: 0.5px solid $black-50;
    margin-bottom: 45px;
    position: relative;
    &::before {
      position: absolute;
      width: 30px;
      height: 4px;
      content: "";
      left: 0;
      top: -2px;
      background-color: $black;
    }
    .chapter-num {
      margin-top: 30px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: 0.08em;
      color: $black;
    }
    .chapter-btn {
      position: absolute;
      width: 40px;
      height: 40px;
      right: 0px;
      top: 20px;
      .chapter-reduce-btn {
        width: 40px;
        height: 40px;
      }
    }
    .chapter-btn-isExpand {
      top: 24px;
    }
    .chapter-center {
      flex-direction: column-reverse;
      margin: 0;
      .chapter-title {
        margin-top: 15px;
        font-weight: 700;
        font-size: 20px;
        line-height: 35px;
        letter-spacing: 0.09em;
        color: $black;
      }
      .chapter-status {
        height: 24px;
        margin-left: 51px;
        margin-top: 30px;
        div {
          font-size: 12px !important;
        }
      }
    }
    .chapter-content {
      margin-right: 0px;
      margin-top: 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: $dark-grey;
    }
    .chapter-detail {
      margin-left: 0px;
      .chapter-detail-box {
        height: 60px;
        .chapter-detail-box-item {
          width: 33.33%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .chapter-detail-box-item-label {
            margin-left: 0px;
            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.09em;
            color: rgba(89, 88, 87, 0.8);
          }
          .chapter-detail-box-item-colon {
            display: none;
          }
          .chapter-detail-box-item-value {
            margin-top: 5px;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.09em;
            color: $black;
          }
          &::before {
            height: 15.5px;
            top: 22.5px;
          }
        }
      }
      .chapter-detail-btn {
        width: 100%;
        height: 60px;
        .chapter-detail-btn-text {
          margin-left: 30px;
          width: 96px;
          height: 18px;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          display: flex;
          align-items: center;
          letter-spacing: 0.08em;
          color: $white;
          white-space: nowrap;
        }
        .chapter-detail-btn-icon {
          margin-right: 20px;
        }
        &:hover {
          &::before {
            animation: unset;
          }
          &::after {
            animation: unset;
          }
        }
      }
    }
  }
}
.chapterItem-expend {
  &::before {
    width: 115px;
  }
  .chapter-num {
    margin-top: 31px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 700;
    font-size: 50px;
    line-height: 70px;
    letter-spacing: 0.08em;
    color: $black;
  }
  @include mq(sm) {
    &::before {
      width: 60px;
    }
    .chapter-num {
      margin-top: 30px;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 100%;
      letter-spacing: 0.08em;
      color: $black;
    }
    .chapter-center {
      .chapter-status {
        margin-top: 34px;
      }
    }
  }
}
</style>