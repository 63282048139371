<template>
  <div class="testDialogContainerCls">
    <el-dialog
      v-model="viewState.visible"
      :lock-scroll="true"
      width="100%"
      :show-close="false"
      fullscreen
      :destroy-on-close="true"
      custom-class="test-dialog"
    >
      <div class="test-dialog-container">
        <carousel
          :items-to-show="1"
          :modelValue="viewState.currentSlideIndex"
          :mouseDrag="false"
          :touchDrag="false"
          class="test-carousel"
        >
          <slide
            v-for="questionItem in material.test.test_questions"
            :key="questionItem.id"
          >
            <div class="test-section">
              <commonTitle title="確認テスト" :subTitle="material.name"></commonTitle>

              <template v-if="questionItem.question === '概要'">
                <div class="test-dialog-body">
                  <div class="sub-title">はじめに</div>

                  <div class="test-introduction">
                    <p>
                      これより本レッスンの確認テストを行います。<br />
                      確認テストは全{{ material.test.question_count }}問出題され、{{ material.test.passing_score }}問正解で合格となります。<br />
                      <br />
                      準備ができましたら、「次へ」を押してテストを始めてください。
                    </p>
                  </div>
                </div>

                <div class="test-footer">
                  <button
                    class="next-button"
                    @click="
                      () => {
                        capturePaper();
                        nextTestView();
                      }
                    "
                  >
                    <span class="button-text">次へ</span>
                  </button>
                </div>
              </template>

              <template v-else-if="questionItem.question === '確認'">
                <div class="test-dialog-body">
                  <div class="sub-title">採点前確認</div>

                  <div class="test-confirm-description">
                    <p>
                      回答が完了しましたら、「回答を送信」を押すと採点結果が表示されます。<br>
                      回答内容を確認する場合は、「回答へ戻る」を押してください。
                    </p>
                  </div>

                  <div class="test-question-list">
                    <template
                      v-for="questions in material.test.test_questions"
                      :key="questions.id"
                    >
                      <div
                        v-if="'sort_no' in questions"
                        class="test-question-item"
                      >
                        <div class="test-question-number">
                          {{ questions.sort_no }}.
                          <icon
                            name="note-edit-icon"
                            hoverName="note-edit-active-icon"
                            class="editAnswerIcon"
                            @click="editAnswer(questions.sort_no)"
                          />
                        </div>

                        <div class="test-question">
                          <p>
                            {{ questions.question }}
                          </p>
                        </div>

                        <div
                          v-for="choice in questions.test_choices"
                          :key="choice.id"
                        >
                          <button
                            class="submit-area-button unclickable-button"
                            :class="{
                              'submit-area-button--selected':
                                testValueObject[questions.key][
                                  choice.key
                                ] === true,
                            }"
                          >
                            {{ choice.value }}
                          </button>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <div class="test-footer test-footer-space-around">
                  <button
                    class="prev-button"
                    @click="preTestView()"
                  >
                    <span class="button-text">回答へ戻る</span>
                  </button>

                  <button
                    class="next-button"
                    @click="sendTestValue(material.test.key)"
                  >
                    <span class="button-text">回答を送信</span>
                  </button>
                </div>
              </template>

              <template v-else-if="questionItem.question === '結果'">
                <div v-if="Object.keys(testResult).length > 0" class="test-dialog-body">
                  <div class="sub-title">{{ material.test.question_count }}問中{{ testResult.score }}問正解</div>

                  <div
                    class="test-result"
                    :class="{
                      pass: testResult.is_passed
                    }"
                  >
                    <template v-if="testResult.is_passed">合格</template>
                    <template v-else>不合格</template>
                  </div>

                  <div class="test-result-detail-operation">
                    <p class="test-result-detail-operation-text">
                      <template v-if="viewState.isShowResult">
                        回答結果を閉じる<icon name="close-btn" class="close-button-icon" @click="viewState.isShowResult = !viewState.isShowResult" />
                      </template>
                      <template v-else>
                        回答結果を開く<icon name="open-btn" class="open-button-icon" @click="viewState.isShowResult = !viewState.isShowResult" />
                      </template>
                    </p>
                  </div>

                  <div
                    v-if="viewState.isShowResult"
                    id="testResultDetail"
                    class="test-result-question-list"
                  >
                    <template
                      v-for="testQuestion in material.test.test_questions"
                      :key="testQuestion.id"
                    >
                      <div
                        v-if="'sort_no' in testQuestion && testQuestion.key in testResult.result_detail"
                        class="test-question-item"
                      >
                        <div class="test-question-number">
                          {{ testQuestion.sort_no }}.
                          <span v-if="testResult.result_detail[testQuestion.key].is_correct" class="correct">
                            正解
                          </span>
                          <span v-else class="incorrect">
                            不正解
                          </span>
                        </div>

                        <div class="test-question-text">
                          <p>
                            {{ testQuestion.question }}
                          </p>
                        </div>

                        <div class="test-choice-list">
                          <div
                            v-for="choice in testQuestion.test_choices"
                            :key="choice.id"
                            class="test-choice-item"
                          >
                            <p v-if="testResult.result_detail[testQuestion.key].correct_choice_keys.includes(choice.key)" class="correct-choice">
                              {{ choice.value }}
                            </p>
                            <p v-else-if="testResult.result_detail[testQuestion.key].incorrect_choice_keys.includes(choice.key)" class="incorrect-choice">
                              {{ choice.value }}
                            </p>
                            <p v-else class="unselected-choice">
                              {{ choice.value }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <div class="test-footer">
                  <button
                    class="next-button"
                    @click="
                      () => {
                        closeModal();
                        finishTestView();
                      }
                    "
                  >
                    <span class="button-text">受講終了</span>
                  </button>
                </div>
              </template>

              <template v-else>
                <div class="test-dialog-body">
                  <div class="sub-title">{{ questionItem.sort_no }}問/{{ material.test.question_count }}問</div>

                  <div class="test-question">
                    <p>
                      {{ questionItem.question }}
                    </p>
                  </div>

                  <div
                    v-for="choice in questionItem.test_choices"
                    :key="choice.id"
                  >
                    <button
                      class="submit-area-button"
                      :class="{
                        'submit-area-button--selected':
                          testValueObject[questionItem.key][
                            choice.key
                          ] === true,
                      }"
                      @click="
                        () => {
                          if (questionItem.has_many_corrects) {
                            toggleTestValue(questionItem.key, choice.key);
                          } else {
                            radioTestValue(questionItem.key, choice.key);
                          }
                        }
                      "
                    >
                      {{ choice.value }}
                    </button>
                  </div>
                </div>

                <div
                  class="test-footer"
                  :class="{
                    'test-footer-space-around': questionItem.sort_no > 1 || !viewState.changingAnswer
                  }"
                >
                  <template v-if="viewState.changingAnswer">
                    <button
                      class="next-button"
                      @click="
                        () => {
                          if (
                            // 選択時のみ遷移可能
                            Object.values(
                              testValueObject[questionItem.key]
                            ).includes(true)
                          ) {
                            backConfirmView();
                          }
                        }
                      "
                    >
                      <span class="button-text">採点前確認へ</span>
                    </button>
                  </template>

                  <template v-else>
                    <button
                      v-if="questionItem.sort_no > 1"
                      class="prev-button"
                      @click="preTestView()"
                    >
                      <span class="button-text">前へ</span>
                    </button>

                    <button
                      class="next-button"
                      @click="
                        () => {
                          if (
                            // 選択時のみ遷移可能
                            Object.values(
                              testValueObject[questionItem.key]
                            ).includes(true)
                          ) {
                            nextTestView();
                          }
                        }
                      "
                    >
                      <span class="button-text">次へ</span>
                    </button>
                  </template>
                </div>
              </template>
            </div>
          </slide>
        </carousel>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  ElDialog,
} from "element-plus";
import { defineComponent, nextTick, reactive, ref, onMounted } from "vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";

export default defineComponent({
  props: {
    material: {
      default: {},
    },
    testValueObject: {
      default: {},
    },
    testResult: {
      default: {},
    },
  },
  emits: [
    "resetTestView",
    "finishCapturePaper",
    "finishModalContent",
    "toggleTestValue",
    "radioTestValue",
    "sendTestValue",
    "capturePaper"
  ],
  components: {
    ElDialog,
    Carousel,
    Slide,
    commonTitle
  },
  setup(props, context) {
    const viewState = reactive({
      visible: false,
      currentSlideIndex: 0,
      changingAnswer: false,
      isShowResult: true,
    });

    const windowWidth = ref(window.innerWidth + "px");

    // method
    const init = () => {
      viewState.visible = true;
      nextTick(() => {});
    };
    const preTestView = () => {
      const slideIndex = viewState.currentSlideIndex - 1;
      resetScrollTop(slideIndex);
      viewState.currentSlideIndex = slideIndex;
    };
    const nextTestView = () => {
      const slideIndex = viewState.currentSlideIndex + 1;
      resetScrollTop(slideIndex);
      viewState.currentSlideIndex = slideIndex;
    };
    const resetTestView = () => {
      context.emit("resetTestView");
    };
    const toggleTestValue = (questionKey, choiceKey) => {
      context.emit("toggleTestValue", questionKey, choiceKey);
    };
    const radioTestValue = (questionKey, choiceKey) => {
      context.emit("radioTestValue", questionKey, choiceKey);
    };
    const sendTestValue = (testKey) => {
      context.emit("sendTestValue", testKey);
    };
    const finishTestView = () => {
      context.emit("finishModalContent");
    }
    const capturePaper = () => {
      context.emit("capturePaper");
    }
    const closeModal = () => {
      viewState.visible = false;
    }
    const editAnswer = (slideIndex) => {
      resetScrollTop(slideIndex);
      viewState.currentSlideIndex = slideIndex;
      viewState.changingAnswer = true;
    }
    const backConfirmView = () => {
      const slideIndex = props.material.test.question_count + 1;
      resetScrollTop(slideIndex);
      viewState.currentSlideIndex = slideIndex;
      viewState.changingAnswer = false;
    }
    const resetScrollTop = (index) => {
      document.getElementsByClassName('test-section')[index].scrollTop = 0;
    }
    const updateWindowSize = () => {
      windowWidth.value = window.innerWidth + "px";
    }

    onMounted(() => {
      window.addEventListener('resize', updateWindowSize)
    })

    return {
      viewState,
      windowWidth,

      // method
      init,
      preTestView,
      nextTestView,
      resetTestView,
      toggleTestValue,
      radioTestValue,
      sendTestValue,
      finishTestView,
      capturePaper,
      closeModal,
      editAnswer,
      backConfirmView,
    };
  },
});
</script>
<style lang="scss" scoped>
.testDialogContainerCls {
  .test-dialog {
    .common-title {
      max-width: 993px;
      margin-bottom: 50px;
      
      @include mq(sm) {
        max-width: v-bind(windowWidth);
        margin-bottom: 30px;
      }

      &::before {
        top: 0px;
        @include mq(sm) {
          top: 30px;
        }
      }

      ::v-deep(.common-title-area) {
        @include mq(sm) {
          height: unset;
        }
        
        .common-titles-title {
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 40px;
          line-height: 60px;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: $black;

          @include mq(sm) {
            margin-top: 10px;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 0.08em;
            color: $black;
            text-align: left;
            margin-right: 50px;
          }
        }

        .common-titles-sub-title {
          width: 500px;
          display: flex;
          justify-content: flex-start;
          line-height: unset;

          @include mq(sm) {
            width: 100%;
            line-height: 100%;
          }
        }
      }
    }
  }

  p {
    text-align: left;
    line-height: 30px;
    letter-spacing: .1em;
    white-space: pre-wrap;
  }

  .test-dialog-container {
    display: flex;
    flex-direction: column;
    background: $white;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 993px;
    min-height: 494px;
    height: 80%;
    box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    
    @include mq(sm) {
      height: 100%;
    }
  }

  .test-carousel {
    max-width: 993px;
    height: 100%;

    @include mq(sm) {
      height: 100%;
      max-width: v-bind(windowWidth);
    }
  }

  .test-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 50px;
    text-align: center;
    background: white;
    overflow-y: auto;

    @include mq(sm) {
      padding: 30px 20px;
    }
  }

  .test-dialog-body {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.1em;
    margin-bottom: 50px;

    @include mq(sm) {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }

  .sub-title {
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: .08em;
    font-weight: 700;

    @include mq(sm) {
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0.08em;
    }
  }

  .test-question {
    margin-bottom: 15px;
  }

  .test-confirm-description {
    margin-bottom: 50px;
  }

  .test-result-detail-operation {
    margin-bottom: 50px;
  }

  .test-result-detail-operation-text {
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: .08em;

    @include mq(sm) {
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0.08em;
    }
  }

  .open-button-icon,
  .close-button-icon {
    width: 26px;
    height: 26px;
    margin-left: 10px;
    margin-bottom: -3px;
  }

  .test-question-list {
    margin-bottom: 20px;

    .unclickable-button {
      cursor: default;
      
      &:hover {
        color: inherit;
        border-color: $black;
      }

      &.submit-area-button--selected {
        &:hover {
          color: white;
          border: 2px solid $primary;
        }
      }
    }
  }

  .test-question-item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
  
  .test-question-number {
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: .08em;
    font-weight: 700;
    text-align: left;

    @include mq(sm) {
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0.08em;
    }

    .correct {
      color: $secondary;
    }

    .incorrect {
      color: $red;
    }
  }

  .editAnswerIcon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-bottom: 3px;
  }

  .test-result {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: $red;
    margin-bottom: 15px;

    @include mq(sm) {
      font-size: 30px;
      line-height: 100%;
      letter-spacing: 0.08em;
    }

    &.pass {
      color: $secondary;
    }
  }

  .test-question-text {
    margin-bottom: 15px;
  }

  .correct-choice {
    margin-left: 17px;
    color: $secondary;
    position: relative;

    &::before {
      content: "\025cb";
      position: absolute;
      top: 1px;
      left: -17px;
      font-size: 14px;
    }
  }
  .incorrect-choice {
    margin-left: 17px;
    color: $red;
    position: relative;

    &::before {
      content: "\02715";
      position: absolute;
      top: -1px;
      left: -16px;
      font-size: 17px;
    }
  }

  .unselected-choice {
    margin-left: 17px;
  }
  
  .submit-area-button {
    margin: 10px auto;
    width: 100%;
    text-align: left;
    padding: 1rem;
    font-size: 18px;
    border: 2px solid $black;
    position: relative;
    letter-spacing: .1em;

    &:hover {
      color: $primary;
      border: 2px solid $primary;
    }

    &--selected {
      color: white;
      background-color: $primary;
      border: 2px solid $primary;

      &:hover {
        color: white;
      }
    }
  }

  .test-footer {
    display: flex;
    margin-top: auto;
    justify-content: center;

    &.test-footer-space-around {
      justify-content: space-around;;
    }
  }

  .prev-button {
    max-width: 30%;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $black;
    cursor: pointer;
    
    .button-text {
      color: $dark-grey;
      font-size: 16px;
    }
  }

  .next-button {
    max-width: 30%;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $black;
    cursor: pointer;
    
    .button-text {
      color: $white;
      font-size: 16px;
    }
  }
}
</style>

<style lang="scss">
.testDialogContainerCls {
  .el-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      rgba(54, 131, 245, 0.1);
    backdrop-filter: blur(5px);
  }

  .test-dialog {
    .el-dialog__body {
      padding: 0;
      overflow: scroll;
      margin: 0 auto;

      .test-carousel {
        .carousel__viewport {
          height: 100%;

          .carousel__track {
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
